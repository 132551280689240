import React from "react";

const DollerConverter = ({ value }) => {
  //   const value = 4;

  return (
    <>
      <div className={"doller " + (value < 0 ? "doller_erro" : "")}>
        {value < 0 && <div className="doller_negative">-</div>}
        <div className="ddoller_sign">$</div>
        <div className={"doller_number"}>{Math.abs(value).toFixed(2)}</div>
      </div>
    </>
  );
};

export default DollerConverter;

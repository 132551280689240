import React, { useEffect, useState } from "react";
import { Nav } from "../common/Nav";
import { Container } from "../Container";
import { NavSlider } from "../common/NavSlider";
import { Dropdown } from "../common/Dropdown";
import { Table } from "../common/Table";
import { GetData } from "../../utils/page_data";
// import userEvent from "@testing-library/user-event";

export const FullBudget = ({ setroute, route, user_data }) => {
  // const [dropdown_select, setdropdown_select] = useState(["Barebones"]);
  const [dropdown_select, setdropdown_select] = useState(["Full Budget"]);

  return (
    <Container>
      <div className="full_budget">
        <Dropdown
          {...{
            options: [
              {
                key: "Full Budget",
                value: "Full Budget",
              },
              {
                key: "Barebones",
                value: "Barebones",
              },
              {
                key: "Income type",
                value: "Income type",
              },
              {
                key: "Monthly fixed expenses - Payment fixed",
                value: "Monthly fixed expenses - Payment fixed",
              },
              {
                key: "Monthly variable expenses - Payment varies",
                value: "Monthly variable expenses - Payment varies",
              },
              {
                key: "Monthly flexible expenses - Payment fixed or varies",
                value: "Monthly flexible expenses - Payment fixed or varies",
              },
            ],
            to_show: "",
            selected: dropdown_select,
            setselected: (key) => {
              // console.log(key);
              setdropdown_select([key]);
            },
            placeholder: "Select One",
          }}
        />
        {/* <div className="full_budget_main"> */}
        {/* <Nav {...{ setroute, route }} /> */}
        {/* </div> */}
        <div className="table_group">
          {["Full Budget", "Barebones", "Income type"].includes(
            dropdown_select[0]
          ) && (
            <Table
              {...{
                tclass: "",
                theader: ["Income type", "Amount"],
                tbody: user_data?.full_budget?.income_type,
              }}
            />
          )}
          {[
            "Full Budget",
            "Barebones",
            "Monthly fixed expenses - Payment fixed",
          ].includes(dropdown_select[0]) && (
            <Table
              {...{
                tclass: "",
                theader: ["Monthly fixed expenses - Payment fixed", "Amount"],
                tbody: user_data?.full_budget?.monthly_fixed_expenses,
              }}
            />
          )}
          {[
            "Full Budget",
            "Barebones",
            "Monthly variable expenses - Payment varies",
          ].includes(dropdown_select[0]) && (
            <Table
              {...{
                tclass: "",
                theader: [
                  "Monthly variable expenses - Payment varies",
                  "Amount",
                ],
                tbody: user_data?.full_budget?.monthly_variable_expenses,
              }}
            />
          )}
          {[
            "Full Budget",
            "Monthly flexible expenses - Payment fixed or varies",
          ].includes(dropdown_select[0]) && (
            <Table
              {...{
                tclass: "",
                theader: [
                  "Monthly flexible expenses - Payment fixed or varies",
                  "Amount",
                ],
                tbody: user_data?.full_budget?.monthly_flexible_expenses,
              }}
            />
          )}
          {["Full Budget"].includes(dropdown_select[0]) && (
            <Table
              {...{
                tclass: "table_total",
                theader: ["Full Budget", "Total"],
                tbody: user_data?.full_budget.full_budget,
              }}
            />
          )}
          {["Barebones"].includes(dropdown_select[0]) && (
            <Table
              {...{
                tclass: "table_total",
                theader: ["Barebones Budget", "Total"],
                tbody: user_data?.full_budget.barebones_budget,
              }}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

import React, { useLayoutEffect, useRef, useState } from 'react'
import { Images } from '../../image/Images';

let lastId = 0;

export const CustInput = ({ custclass, type, inp_obj, PinArrayHandler }) => {
    const current_inp_obj = {
      error: false,
      title: "",
      value: "",
      on_blur: () => "",
      placeholder: "",
      on_event: () => "",
      on_keydown: () => "",
      error_str: "",
      checked: false,
      readonly: false,
      button_type: "horizontal",
      selection: "single",
      to_show_data: [],
      // note include time also in date_formatter() -> dev
      date_format: "month_short / date / year_long weekday_long",
      options: [],
    };
    lastId++;
    const [ShowPassword, setShowPassword] = useState(false);
    const [values, setvalues] = useState({
      calender_height: 0,
      calender_is_above: false,
      calender_is_open: false,
    });
  
    const calender = useRef();
    const calender_main = useRef();
    // console.log(inp_obj?.error);
    useLayoutEffect(() => {
      if (calender.current) {
        setvalues({
          ...values,
          calender_is_above:
            window.innerHeight -
              calender_main?.current?.getBoundingClientRect()?.bottom >
            calender_main?.current?.getBoundingClientRect()?.top
              ? false
              : true,
          calender_height: calender.current.children[0].scrollHeight,
        });
      }
    }, [inp_obj, values.calender_is_open]);
  
    switch (type) {
      case "text":
        return (
          <div
            className={"text_group cust_inp" + (custclass ? ` ${custclass}` : "")}
          >
            <label htmlFor={`inp_id${lastId}`}>
              {(inp_obj.title || current_inp_obj.title) && (
                <div className="inp_title">
                  {/* <label htmlFor={`inp_id${current_inp_obj.id}`}> */}
                  {inp_obj.title || current_inp_obj.title}
                </div>
              )}
              <input
                error={(inp_obj?.error || current_inp_obj.error).toString()}
                type="text"
                onBlur={inp_obj.on_blur || current_inp_obj.on_blur}
                value={inp_obj.value || current_inp_obj.value}
                onChange={inp_obj.on_event || current_inp_obj.on_event}
                readOnly={inp_obj.readonly || current_inp_obj.readonly}
                placeholder={inp_obj.placeholder || current_inp_obj.placeholder}
                id={`inp_id${lastId}`}
                onKeyDown={inp_obj.on_keydown || current_inp_obj.on_keydown}
              />
            </label>
            <div
              className="error_otp_not_match"
              style={{
                padding: "4px 0 0 0",
                transform:
                  inp_obj.error_str || current_inp_obj.error_str
                    ? "scaley(1)"
                    : "scaley(0)",
                transition: "transform 0.1s linear",
              }}
            >
              {inp_obj.error_str}
            </div>
          </div>
        );
      case "email":
        return (
          <div
            className={
              "text_group email_group cust_inp" +
              (custclass ? ` ${custclass}` : "")
            }
          >
            {/* {console.log((inp_obj?.error || current_inp_obj.error).toString())} */}
            <label
              htmlFor={`inp_id${lastId}`}
              className={
                (inp_obj?.error || current_inp_obj.error).toString() == "true"
                  ? "error"
                  : ""
              }
            >
              {(inp_obj.title || current_inp_obj.title) && (
                <div className="inp_title">
                  {/* <label htmlFor={`inp_id${current_inp_obj.id}`}> */}
                  {inp_obj.title || current_inp_obj.title}
                </div>
              )}
              <div
                className={
                  (inp_obj?.error || current_inp_obj.error).toString() == "true"
                    ? "error_icons"
                    : ""
                }
              >
                {/* <Images img_var={"mail"} /> */}
              </div>
              <input
                error={(inp_obj?.error || current_inp_obj.error).toString()}
                type="text"
                onBlur={inp_obj.on_blur || current_inp_obj.on_blur}
                value={inp_obj.value || current_inp_obj.value}
                onChange={inp_obj.on_event || current_inp_obj.on_event}
                readOnly={inp_obj.readonly || current_inp_obj.readonly}
                placeholder={inp_obj.placeholder || current_inp_obj.placeholder}
                id={`inp_id${lastId}`}
                onKeyDown={inp_obj.on_keydown || current_inp_obj.on_keydown}
              />
            </label>
            <div
              className="error_otp_not_match"
              style={{
                padding: "4px 0 0 0",
                transform:
                  inp_obj.error_str || current_inp_obj.error_str
                    ? "scaley(1)"
                    : "scaley(0)",
                transition: "transform 0.1s linear",
              }}
            >
              {inp_obj.error_str}
            </div>
          </div>
        );
      case "pin":
        return (
          <div
            className={
              "text_group email_group cust_inp" +
              (custclass ? ` ${custclass}` : "")
            }
          >
            <label htmlFor={`inp_id${lastId}`}>
              {(inp_obj.title || current_inp_obj.title) && (
                <div className="inp_title">
                  {/* <label htmlFor={`inp_id${current_inp_obj.id}`}> */}
                  {inp_obj.title || current_inp_obj.title}
                </div>
              )}
              {PinArrayHandler.map((value, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  name={`otp-${index}`}
                  placeholder="#"
                  id={`inp_id${lastId}`}
                  value={value}
                  onChange={inp_obj.on_event || current_inp_obj.on_event}
                  onKeyDown={inp_obj.on_keydown || current_inp_obj.on_keydown}
                />
              ))}
            </label>
            <div
              className="error_otp_not_match"
              style={{
                padding: "4px 0 0 0",
                transform:
                  inp_obj.error_str || current_inp_obj.error_str
                    ? "scaley(1)"
                    : "scaley(0)",
                transition: "transform 0.1s linear",
              }}
            >
              {inp_obj.error_str}
            </div>
          </div>
        );
      case "password": {
        return (
          <div
            className={
              "password_group cust_inp" + (custclass ? ` ${custclass}` : "")
            }
          >
            <label htmlFor={`inp_id${lastId}`}>
              {(inp_obj.title || current_inp_obj.title) && (
                <div className="inp_title">
                  {/* <label htmlFor={`inp_id${current_inp_obj.id}`}> */}
                  {inp_obj.title || current_inp_obj.title}
                </div>
              )}
              <div
                className="password_icon"
                onClick={() => {
                  // FunPassword(`inp_id${lastId - 1}`)
                  setShowPassword((prev) => !prev);
                }}
              >
                {/* <Images img_var="password" /> */}
              </div>
              <input
                error={(inp_obj?.error || current_inp_obj.error).toString()}
                type={!ShowPassword ? "password" : "text"}
                onBlur={inp_obj.on_blur || current_inp_obj.on_blur}
                value={inp_obj.value || current_inp_obj.value}
                onChange={inp_obj.on_event || current_inp_obj.on_event}
                readOnly={inp_obj.readonly || current_inp_obj.readonly}
                placeholder={inp_obj.placeholder || current_inp_obj.placeholder}
                id={`inp_id${lastId}`}
                className="passowrd"
                onKeyDown={inp_obj.on_keydown || current_inp_obj.on_keydown}
              />
            </label>
            <div
              className="error_otp_not_match"
              style={{
                padding: "4px 0 0 0",
                transform:
                  inp_obj.error_str || current_inp_obj.error_str
                    ? "scaley(1)"
                    : "scaley(0)",
                transition: "transform 0.1s linear",
              }}
            >
              {inp_obj.error_str}
            </div>
          </div>
        );
      }
  
      case "textarea":
        return (
          <div
            className={"text_group text_area cust_inp" + (custclass ? ` ${custclass}` : "")}
          >
            <label htmlFor={`inp_id${lastId}`}>
              {(inp_obj.title || current_inp_obj.title) && (
                <div className="inp_title">
                  {/* <label htmlFor={`inp_id${current_inp_obj.id}`}> */}
                  {inp_obj.title || current_inp_obj.title}
                </div>
              )}
              <textarea
                rows="2"
                style={{ resize: "none", outline: "0", width: "100%" }}
                error={(inp_obj?.error || current_inp_obj.error).toString()}
                value={inp_obj.value || current_inp_obj.value}
                onBlur={inp_obj.on_blur || current_inp_obj.on_blur}
                onChange={inp_obj.on_event || current_inp_obj.on_event}
                readOnly={inp_obj.readonly || current_inp_obj.readonly}
                placeholder={inp_obj.placeholder || current_inp_obj.placeholder}
                id={`inp_id${lastId}`}
              />
            </label>
          </div>
        );
      case "search":
        return (
          <div
            className={
              "search_group cust_inp" + (custclass ? ` ${custclass}` : "")
            }
          >
            <label htmlFor={`inp_id${lastId}`}>
              <Images get="search" />
              <input
                type="text"
                value={inp_obj.value || current_inp_obj.value}
                onChange={inp_obj.on_event || current_inp_obj.on_event}
                readOnly={inp_obj.readonly || current_inp_obj.readonly}
                placeholder={inp_obj.placeholder || current_inp_obj.placeholder}
                id={`inp_id${lastId}`}
              />
            </label>
          </div>
        );
      case "checkbox":
        return (
          <div
            className={
              "checkbox_group cust_inp" + (custclass ? ` ${custclass}` : "")
            }
            onClick={inp_obj.on_event || current_inp_obj.on_event}
          >
            <div className="cust_checkbox_group">
              <div
                className={
                  "cust_checkbox" +
                  (inp_obj.value || current_inp_obj.value ? " active" : "")
                }
              >
                {/* <Images img_var={"tick"} /> */}
              </div>
            </div>
            {(inp_obj.title || current_inp_obj.title) && (
              <div className="inp_title">
                {inp_obj.title || current_inp_obj.title}
              </div>
            )}
          </div>
        );
  
      default:
        break;
    }
  
    return <div>insufficient data</div>;
  };
  
export const nav_data = [
  {
    name: "Barebone Budget",
    icon: "budget",
    to: "Full budget",
  },
  {
    name: "Debt Elimination Calculator",
    icon: "calculator",
    to: "Debt elimination calculator",
  },
  {
    name: "Digital Envelope",
    icon: "message",
    to: "Digital envelope",
  },
  {
    name: "Barebone Emergency Fund",
    icon: "bookmark",
    to: "Full emergency fund",
  },
  // {
  //   name: "Monthly Affirmation",
  //   icon: "calender",
  //   to: "Monthly affirmation",
  // },
  {
    name: "52 Week Saving Challenge",
    icon: "savings",
    to: "52 week saving challenge",
  },
];

import { useEffect, useState } from "react";
import "./style/main.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Main } from "./components/Main/Main";
import { FullBudget } from "./components/FullBudget/FullBudget";
import { Nav } from "./components/common/Nav";
import { GetData, page_data } from "./utils/page_data";
import DebtEliminationCalculator from "./components/DebtEliminationCalculator/DebtEliminationCalculator";
import FullEmergencyFund from "./components/FullEmergencyfund/FullEmergencyFund";
import Digitalenvelop from "./components/Digitalenvelop/Digitalenvelop";
import Monthlyaffirmation from "./components/Monthlyaffirmation/Monthlyaffirmation";
import Weeksaving from "./components/52weeksaving/Weeksaving";
import { Test } from "./components/Test";
import Loader from "./components/common/Loader";
import { Testing } from "./components/Testing";

function App() {
  const [route, setroute] = useState("");
  const [user_data, setuser_data] = useState(page_data);
  const [LoaderHandler, setLoaderHandler] = useState(false);
  useEffect(() => {
    // return;
    // ++++++++++++++++ Loader On ++++++++++++++++++ //
    setLoaderHandler(true);
    GetData(({ status, page_data }) => {
      // console.log(page_data);
      if (status) {
        // ++++++++++++++++ Loader Off ++++++++++++++++++ //
        setLoaderHandler(false);
      }
      setuser_data(page_data);
      let temp_route = window.location.search.slice(1).split(":::")[3];
      if (temp_route) {
        setroute(
          [
            "",
            "Full budget",
            "Debt elimination calculator",
            "Digital envelope",
            "Full emergency fund",
            // "Monthly affirmation",
            "52 week saving challenge",
          ][temp_route]
        );
      }
    });
  }, []);
  // console.log(route);
  const get_route = () => {
    switch (route) {
      case "":
        return (
          <>
            {/* <Navbar {...{ setroute }} /> */}
            <Main {...{ setroute }} />
          </>
        );
      case "Full budget":
        return <FullBudget {...{ setroute, route, user_data }} />;
      case "Debt elimination calculator":
        return (
          <DebtEliminationCalculator {...{ setroute, route, user_data }} />
        );
      case "Digital envelope":
        return <Digitalenvelop {...{ setroute, route, user_data }} />;
      case "Full emergency fund":
        return <FullEmergencyFund {...{ setroute, route, user_data }} />;
      // case "Monthly affirmation":
      //   return <Monthlyaffirmation {...{ setroute, route, user_data }} />;
      case "52 week saving challenge":
        return <Weeksaving {...{ setroute, route, user_data }} />;
      case "testing":
        return <Testing />;

      default:
        break;
    }
  };
  // console.log(route);
  return (
    <div className="main_body">
      <Loader LoaderHandler={LoaderHandler} />
      {route && <Nav {...{ route, setroute }} />}

      {get_route()}
    </div>
  );
}

export default App;

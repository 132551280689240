import React, { useLayoutEffect, useState } from "react";
import { Container } from "../Container";
import { Dropdown } from "../common/Dropdown";
import { Table } from "../common/Table";
import Graph, { fillZerosWithSlope } from "../common/Graph";
import { date_formatter, getLastMonthsDates } from "../common/Date";
import { colordata } from "../../utils/page_data";
import DollerConverter from "../common/DollerConverter";
function convertToSystemTimeZone(dateString) {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return new Date(dateString).toLocaleString("en-US", { timeZone });
}
const Digitalenvelop = ({ setroute, route, user_data }) => {
  const [dropdown_select, setdropdown_select] = useState(["All"]);
  const [log, setlog] = useState({});
  const numberOfMonths = 4; // You can change this to any desired value
  const lastMonthsDates = getLastMonthsDates(numberOfMonths)?.map((el, i) =>
    date_formatter(el, "m_long  year_long")
  );
  const [MonthArray, setMonthArray] = useState([
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]);
  const [DigitalenvelopGraph, setDigitalenvelopGraph] = useState({});
  useLayoutEffect(() => {
    // function findEarliestAndLatestDates(dateArray) {
    //   if (dateArray.length === 0) {
    //     return { earliest: null, latest: null };
    //   }

    //   let earliestDate = new Date(dateArray[0]);
    //   let latestDate = new Date(dateArray[0]);

    //   for (let i = 1; i < dateArray.length; i++) {
    //     const currentDate = new Date(dateArray[i]);
    //     setlog((prev) => [
    //       ...prev,
    //       {
    //         i,
    //         currentDate: new Date(dateArray[i]),
    //       },
    //     ]);

    //     if (currentDate < earliestDate) {
    //       earliestDate = currentDate;
    //     }

    //     if (currentDate > latestDate) {
    //       latestDate = currentDate;
    //     }
    //   }

    //   return { earliest: earliestDate, latest: latestDate };
    // }
    function findEarliestAndLatestDates(dateArray) {
      if (dateArray.length === 0) {
        return { earliest: null, latest: null };
      }

      const parseDate = (dateStr) => new Date("01" + dateStr);

      let earliestDate = parseDate(dateArray[0]);
      let latestDate = parseDate(dateArray[0]);
      console.log({ dateArray, earliestDate, latestDate });

      for (let i = 1; i < dateArray.length; i++) {
        const currentDate = parseDate(dateArray[i]);

        if (currentDate < earliestDate) {
          earliestDate = currentDate;
        }

        if (currentDate > latestDate) {
          latestDate = currentDate;
        }
      }

      return { earliest: earliestDate, latest: latestDate };
    }
    function generateMonthYearArray(startDateStr, endDateStr) {
      const startDate = new Date(startDateStr);
      const endDate = new Date(endDateStr);
      console.log(startDate.toString() == endDate.toString());
      if (startDate.toString() == endDate.toString()) {
        console.log(1);
        let last_startdate = new Date(startDate);
        last_startdate.setMonth(last_startdate.getMonth() + 1);
        let first_startdate = new Date(startDate);
        first_startdate.setMonth(first_startdate.getMonth() - 1);
        return [
          {
            month: new Date(first_startdate).toLocaleString("default", {
              month: "long",
            }),
            // year: new Date(first_startdate).getFullYear(),
            year: new Date(first_startdate).getFullYear(),
          },
          {
            month: new Date(startDate).toLocaleString("default", {
              month: "long",
            }),
            year: new Date(startDate).getFullYear(),
          },
          // {
          //   month: new Date(last_startdate).toLocaleString("default", {
          //     month: "long",
          //   }),
          //   year: new Date(last_startdate).getFullYear(),
          // },
        ];
      }

      if (startDate > endDate) {
        [startDate, endDate] = [endDate, startDate]; // Swap the dates if startDate is later
      }

      const monthYearArray = [];

      while (startDate <= endDate) {
        const month = startDate.toLocaleString("default", { month: "long" });
        const year = startDate.getFullYear();
        monthYearArray.push({ month, year });
        startDate.setMonth(startDate.getMonth() + 1);
      }

      return monthYearArray;
    }
    if (dropdown_select[0] == "All") {
      let sorted_unique = [
        ...new Set(
          user_data?.digital_envelope
            .map((el) => el.detail.map((el2) => el2.month + " " + el2.year))
            .flat()
        ),
      ].sort((a, b) => new Date(a) - new Date(b));
      const dateArray = [...sorted_unique];
      // console.log({ dateArray });
      const { earliest, latest } = findEarliestAndLatestDates(dateArray);

      // console.log("Earliest Date:", earliest);
      // console.log("Latest Date:", latest);

      const monthYearArray = generateMonthYearArray(earliest, latest).map(
        (el) => `${el.month} ${el.year}`
      );
      let t_dataset = user_data?.digital_envelope.map((el1, i) => {
        return {
          label: el1.type,
          borderColor: colordata[i % 20],
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          // data: el1.detail.map((el2) => el2.total),
          data: monthYearArray?.map((el, i) => {
            let diff = el.split(" ").map((el) => el.trim());
            console.log(diff);
            let found_data = el1?.detail.filter(
              (el2) => el2.month == diff[0] && el2.year == diff[1]
            );
            return found_data?.length > 0 ? found_data[0].purchases || 0 : 0;
          }),
          // .filter((el) => el),
        };
      });
      setDigitalenvelopGraph({
        labels: monthYearArray,
        datasets: t_dataset,
      });
    } else {
      let sorted_unique = [
        ...new Set(
          user_data?.digital_envelope
            ?.filter((el) => el.type == dropdown_select[0])
            .map((el) => el.detail.map((el2) => el2.month + " " + el2.year))
            .flat()
        ),
      ].sort((a, b) => new Date(a) - new Date(b));
      const dateArray = [...sorted_unique];
      const { earliest, latest } = findEarliestAndLatestDates(dateArray);

      const monthYearArray = generateMonthYearArray(earliest, latest).map(
        (el) => `${el.month} ${el.year}`
      );
      let t_dataset = user_data?.digital_envelope
        ?.filter((el) => el.type == dropdown_select[0])
        .map((el1, i) => {
          return {
            label: el1.type,
            borderColor: colordata[i % 20],
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            data: monthYearArray?.map((el, i) => {
              let diff = el.split(" ").map((el) => el.trim());
              console.log(diff);
              let found_data = el1?.detail.filter(
                (el2) => el2.month == diff[0] && el2.year == diff[1]
              );
              return found_data?.length > 0 ? found_data[0].purchases || 0 : 0;
            }),
            // .filter((el) => el),
          };
        });
      setDigitalenvelopGraph(
        ...user_data?.digital_envelope
          ?.filter((el) => el.type == dropdown_select[0])
          .map((el1, i) => ({
            labels: monthYearArray,
            datasets: [...t_dataset],
          }))
      );
    }
  }, [dropdown_select]);
  // console.log(DigitalenvelopGraph);
  return (
    <>
      <Container>
        <div className="digital_envelop_main">
          <Dropdown
            {...{
              options: [
                {
                  key: "All",
                  value: "All",
                },
                ...user_data?.digital_envelope?.map((el) => ({
                  key: el.type,
                  value: el.type,
                })),
              ],
              to_show: "",
              selected: dropdown_select,
              setselected: (key) => {
                // console.log(key);
                setdropdown_select([key]);
              },
              placeholder: "Select One",
            }}
          />
          <div className="digital_envelop_inner">
            {dropdown_select[0] == "All" && (
              <div className="chart_digital_envelop">
                <div className="total_sections">
                  <div className="total_month">Purchases vs Month</div>
                  <div className="legenddry_section">
                    {user_data?.digital_envelope.map((el1, i) => (
                      <div className="legendry_sign">
                        <div
                          className="sign_l"
                          style={{ backgroundColor: colordata[i % 20] }}
                        ></div>
                        {el1.type}
                      </div>
                    ))}
                  </div>
                </div>
                <Graph data={DigitalenvelopGraph} />
              </div>
            )}

            {user_data.digital_envelope
              .filter((el2, i) => {
                if (dropdown_select[0] == el2.type) {
                  return el2;
                }
                if (dropdown_select[0] == "All") {
                  return el2;
                }
              })
              .map((el1, i) => (
                <div className="digital_envelop">
                  <Table
                    {...{
                      tclass: "",
                      theader: ["Month", "Budget amount", "Purchases", "Total"],
                      tbody:
                        // el1.detail
                        [
                          ...el1.detail
                            .map((el) => ({
                              month: el.month + " " + el.year,
                              budget_amount: (
                                <DollerConverter value={el.budget_amount} />
                              ),
                              purchases: (
                                <DollerConverter value={el.purchases} />
                              ),
                              total: <DollerConverter value={el.total} />,
                            }))
                            .sort(
                              (a, b) =>
                                MonthArray.indexOf(a.month) -
                                MonthArray.indexOf(b.month)
                            ),
                          // .reverse(),
                          {
                            month: (
                              <div className="color_base_primary">Total</div>
                            ),
                            budget_amount: (
                              <div className="color_base_primary">
                                <DollerConverter
                                  value={el1.detail.reduce(
                                    (previous, current) => {
                                      return previous + current.budget_amount;
                                    },
                                    0
                                  )}
                                />
                              </div>
                            ),
                            purchases: (
                              <div className="color_base_primary">
                                <DollerConverter
                                  value={el1.detail.reduce(
                                    (previous, current) => {
                                      return previous + current.purchases;
                                    },
                                    0
                                  )}
                                />
                              </div>
                            ),
                            total: (
                              <div className="color_base_primary">
                                <DollerConverter
                                  value={el1.detail.reduce(
                                    (previous, current) => {
                                      return previous + current.total;
                                    },
                                    0
                                  )}
                                />
                              </div>
                            ),
                            change_color: true,
                          },
                        ]
                          .map((el2) => ({
                            ...el2,
                          }))
                          .map((el1) => [
                            <div>{el1.month}</div>,
                            <div>{el1.budget_amount}</div>,
                            <div>{el1.purchases}</div>,
                            <div>{el1.total}</div>,
                          ]),
                    }}
                  />
                  <div className="table_type_digitalenvelop">{el1.type}</div>
                </div>
              ))}
            {dropdown_select[0] != "All" && (
              <div className="chart_digital_envelop">
                <div className="total_sections">
                  <div className="total_month">Total vs Month</div>
                  <div className="legenddry_section">
                    <div className="legendry_sign">
                      <div
                        className="sign_l"
                        style={{
                          backgroundColor: "#00A86B",
                        }}
                      ></div>
                      {dropdown_select[0]}
                    </div>
                  </div>
                </div>
                <Graph data={DigitalenvelopGraph} />
              </div>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Digitalenvelop;

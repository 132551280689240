import React, { useState } from "react";
import { Container } from "../Container";
import { CustInput } from "../common/CustInput";
import { Table } from "../common/Table";
import DollerConverter from "../common/DollerConverter";

const Weeksaving = ({ setroute, route, user_data }) => {
  const [search, setSearch] = useState("");
  return (
    <>
      <Container>
        <div className="weeksaving">
          {/* <div className="weeksaving_title">Monthly income after tax</div> */}
          <div className="weeksaving_title2">
            <div>
              Savings goal: ${user_data?.weeklysaving?.savinggoal}
              {/* {user_data?.weeklysaving?.data?.reduce((acc, obj) => acc + obj.Deposit, 0).toFixed(2)} */}
            </div>
            <div>
              Current savings: $
              {user_data?.weeklysaving?.savingtotal.toFixed(2)}
            </div>
          </div>
          <div className="search_week">
            <CustInput
              custclass="search_week"
              type="text"
              inp_obj={{
                title: "",
                placeholder: "#week no.",
                value: search,
                on_event: ({ target }) => {
                  setSearch(target.value);
                },
              }}
            />
          </div>
          <div className="week_body">
            <Table
              {...{
                tclass: "",
                theader: ["Week No.", "Deposit", "Balance", "Actual Deposit"],
                tbody: user_data.weeklysaving.data
                  .filter((el, i) => {
                    if (el.week == Number(search)) {
                      return el;
                    }
                    if (search == "") {
                      return el;
                    }
                  })
                  .map((el, i) => [
                    el.week,
                    <DollerConverter value={el.Deposit} />,
                    <DollerConverter value={el.Balance} />,
                    <DollerConverter
                      value={`${
                        el?.actualdeposit ||
                        (0)
                          // Number(el.Deposit) + Number(el.Balance)
                          .toFixed(2)
                      }`}
                    />,
                  ]),
              }}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default Weeksaving;

import React from "react";
import { Images } from "../../image/Images";
import { NavSlider } from "./NavSlider";
import { Container } from "../Container";
import { nav_data } from "../../utils/utils";

export const Nav = ({ setroute, route }) => {
  return (
    <Container>
      <div className="nav">
        <div className="nav_defaults">
          <div
            className="go_back"
            onClick={() => {
              setroute("");
            }}
          >
            <div className="go_back_btn">
              <div className="go_back_btn_translate">
                <Images
                  get={"logo"}
                  cust_class={"logo"}
                  eventclick={() => {
                    setroute("");
                  }}
                />
                <div className="arrow_holder">
                  <Images get={"line_arrow_right"} />
                </div>
              </div>
            </div>
            <div className="goback_txt">Go back</div>
          </div>
          <div className="breadcrumb">
            {/* <span>home</span>
            <span>&gt;</span>
            <span className="current">{route.toLowerCase()}</span> */}
            <Images get="home_logo1" cust_class="home_logo" />
          </div>
          {/* <div className="hamberger_nan" onClick={() => {}}>
            <Images get={"hamburger"} />
          </div> */}
        </div>
        {/* <div className="hamberger_nav">
          {nav_data.map((el, i) => (
            <div key={i} className="hamberger_list">
              <Images get={el.icon} />
              <div className="hamberger_text">{el.name}</div>
            </div>
          ))}
        </div> */}
        <NavSlider {...{ setroute, route }} />
      </div>
    </Container>
  );
};

import React from "react";
import { Images } from "../../image/Images";
import { Container } from "../Container";

export const Main_Section1 = () => {
  return (
    <div className="section1">
      <Container>
        {/* <div className="inner_section1"> */}
        {/* <Images get="bg_img" cust_class="main_bg" />  */}
        <Images get="home_logo"  cust_class="home_logo"/>
        <div className="txt">Financial empowerment through affirmations</div>
        {/* <Images get="logo" cust_class="logo" /> */}

        {/* </div> */}
        <Images get="main_phone" cust_class="phone_img" />
      </Container>
    </div>
  );
};

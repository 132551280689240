const month_arr = [
  ["Jan", "January"],
  ["Feb", "February"],
  ["Mar", "March"],
  ["Apr", "April"],
  ["May", "May"],
  ["June", "June"],
  ["July", "July"],
  ["Aug", "August"],
  ["Sept", "September"],
  ["Oct", "October"],
  ["Nov", "November"],
  ["Dec", "December"],
];
const weekday_arr = [
  ["Sun", "Sunday"],
  ["Mon", "Monday"],
  ["Tue", "Tuesday"],
  ["Wed", "Wednesday"],
  ["Thur", "Thursday"],
  ["Fri", "Friday"],
  ["Sat", "Saturday"],
];

export const date_formatter = (date, format) => {
  let temp_date = date;
  temp_date = {
    date: temp_date.getDate(),
    month: temp_date.getMonth(),
    year: temp_date.getFullYear(),
    weekday: temp_date.getDay(),
  };
  let temp_date_data = {
    month: temp_date.month + 1,
    month_short:
      temp_date.month < 9 ? `0${temp_date.month + 1}` : temp_date.month + 1,
    m_long: month_arr[temp_date.month][1],
    date: temp_date.date < 10 ? `0${temp_date.date}` : temp_date.date,
    year_short: temp_date.year % 100,
    year_long: temp_date.year,
    weekday_short: weekday_arr[temp_date.weekday][0],
    weekday_long: weekday_arr[temp_date.weekday][1],
  };

  for (const el of Object.keys(temp_date_data)) {
    format = format.replaceAll(el, temp_date_data[el]);
  }

  return format;
};

export const getLastMonthsDates = (numberOfMonths) => {
  const today = new Date();
  const lastMonthsDates = [];

  for (let i = 0; i < numberOfMonths; i++) {
    const year = today.getFullYear();
    const month = today.getMonth();
    const date = today.getDate();

    const formattedDate = new Date(year, month + i, 1);
    lastMonthsDates.push(formattedDate);
  }

  return lastMonthsDates;
};

import React from "react";

const Loader = ({ LoaderHandler }) => {
    // console.log(LoaderHandler);
  return (
    <>
      <div
        className="loader"
        style={{
          transform: LoaderHandler ? "scale(1)" : "scale(0)",
        }}
      >
        <div className="loading">
          <img src="https://imgur.com/U9iE8sA.jpg" />
        </div>
      </div>
    </>
  );
};

export default Loader;

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
// const plugin = {
//   id: "custom_canvas_background_color",
//   beforeDraw: (chart, args, options) => {
//     const { ctx } = chart;
//     ctx.save();
//     ctx.globalCompositeOperation = "destination-over";
//     ctx.fillStyle = options.color;
//     ctx.fillRect(0, 0, chart.width, chart.height);
//     ctx.restore();
//   },
//   defaults: {
//     color: "lightGreen",
//   },
// };
export function fillZerosWithSlope(arr) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === 0) {
      let startIndex = i - 1;
      while (startIndex >= 0 && arr[startIndex] === 0) {
        startIndex--;
      }

      let endIndex = i + 1;
      while (endIndex < arr.length && arr[endIndex] === 0) {
        endIndex++;
      }

      if (startIndex >= 0 && endIndex < arr.length) {
        let slope = (arr[endIndex] - arr[startIndex]) / (endIndex - startIndex);
        arr[i] = arr[startIndex] + slope * (i - startIndex);
      } else if (startIndex < 0) {
        arr[i] = arr[endIndex];
      } else {
        arr[i] = arr[startIndex];
      }
    }
  }

  return arr;
}

export const options = {
  responsive: true,
  elements: {
    point: {
      pointStyle: "circle",
      drawActiveElementsOnTop: false,
      radius: 0,
      // default to disabled in all datasets
    },
    // background: "black",
  },

  plugins: {
    // defaults: {
    //   color: "blue",
    // },
    legend: {
      position: "right",
      display: false,
    },
    title: {
      display: false,
      text: "",
    },
  },
};

const Graph = ({ data }) => {
  return (
    data && Object.keys(data).length && <Line options={options} data={data} />
  );
};

export default Graph;

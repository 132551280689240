import React from "react";
import { Main_Section1 } from "./Main_Section1";
import { Images } from "../../image/Images";
import { Main_Section2 } from "./Main_Section2";

export const Main = ({ setroute }) => {
  return (
    <div className="main">
      <Main_Section1 {...{ setroute }} />
      <Main_Section2 {...{ setroute }} />
    </div>
  );
};

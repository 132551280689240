import React, { useState } from "react";
import { Container } from "../Container";
import { CustInput } from "../common/CustInput";
import { Table } from "../common/Table";
import DollerConverter from "../common/DollerConverter";
import { Dropdown } from "../common/Dropdown";

const FullEmergencyFund = ({ setroute, route, user_data }) => {
  const [search, setSearch] = useState("");
  const [dropdown_select, setdropdown_select] = useState(["Barebones"]);
  // const [, settotal] = useState([0,0,0,0]);
  // console.log(user_data.full_emergency_fund.data);
  const compare = (a, b) =>
    a === "Emergency fund requirement" ? (
      <div className="color_base_primary">{b}</div>
    ) : (
      b
    );
  return (
    <>
      <div className="fullEmergencyfund_main">
        <Container>
          <div className="inner_full_emergency">
            <Dropdown
              {...{
                options: [
                  {
                    key: "Full Budget",
                    value: "Full Budget",
                  },
                  {
                    key: "Barebones",
                    value: "Barebones",
                  },
                ],
                to_show: "",
                selected: dropdown_select,
                setselected: (key) => {
                  // console.log(key);
                  setdropdown_select([key]);
                },
                placeholder: "Select One",
              }}
            />
            {/* <CustInput
              custclass="search_fullemergency"
              type="search"
              inp_obj={{
                title: "",
                placeholder: "Search",
                value: search,
                on_event: ({ target }) => {
                  setSearch(target.value);
                },
              }}
            /> */}
            <Table
              {...{
                tclass: "",
                theader: [
                  "Summery",
                  "Monthly",
                  "3 months",
                  "6 months",
                  "9 months",
                  "12 months",
                ],
                tbody: [
                  ...user_data.full_emergency_funds.data
                    .filter((el) =>
                      dropdown_select == "Barebones"
                        ? el.summer !== "Flexible expenses"
                        : true
                    )

                    .map(
                      (el, i) =>
                        // i < 2
                        //   ?
                        [
                          compare(el.summer, el.summer),

                          compare(
                            el.summer,
                            <DollerConverter value={el.monthly} />
                          ),
                          compare(
                            el.summer,
                            <DollerConverter value={el.monthly * 3} />
                          ),
                          compare(
                            el.summer,
                            <DollerConverter value={el.monthly * 6} />
                          ),
                          compare(
                            el.summer,
                            <DollerConverter value={el.monthly * 9} />
                          ),
                          compare(
                            el.summer,
                            <DollerConverter value={el.monthly * 12} />
                          ),
                        ]
                      // : []
                    )
                    .filter((el) => el.length),
                  [
                    compare(
                      "Emergency fund requirement",
                      "Emergency fund requirement"
                    ),
                    compare(
                      "Emergency fund requirement",
                      <DollerConverter
                        value={
                          user_data.full_emergency_funds.total -
                          (dropdown_select == "Barebones"
                            ? Number(
                                user_data.full_emergency_funds.data[2]
                                  ?.monthly || 0
                              )
                            : 0)
                        }
                      />
                    ),
                    compare(
                      "Emergency fund requirement",
                      <DollerConverter
                        value={
                          (user_data.full_emergency_funds.total -
                            (dropdown_select == "Barebones"
                              ? Number(
                                  user_data.full_emergency_funds.data[2]
                                    ?.monthly || 0
                                )
                              : 0)) *
                          3
                        }
                      />
                    ),
                    compare(
                      "Emergency fund requirement",
                      <DollerConverter
                        value={
                          (user_data.full_emergency_funds.total -
                            (dropdown_select == "Barebones"
                              ? Number(
                                  user_data.full_emergency_funds.data[2]
                                    ?.monthly
                                )
                              : 0)) *
                          6
                        }
                      />
                    ),
                    compare(
                      "Emergency fund requirement",
                      <DollerConverter
                        value={
                          (user_data.full_emergency_funds.total -
                            (dropdown_select == "Barebones"
                              ? Number(
                                  user_data.full_emergency_funds.data[2]
                                    ?.monthly
                                )
                              : 0)) *
                          9
                        }
                      />
                    ),
                    compare(
                      "Emergency fund requirement",
                      <DollerConverter
                        value={
                          (user_data.full_emergency_funds.total -
                            (dropdown_select == "Barebones"
                              ? Number(
                                  user_data.full_emergency_funds.data[2]
                                    ?.monthly
                                )
                              : 0)) *
                          12
                        }
                      />
                    ),
                  ],
                ].filter((el) =>
                  el?.join(", ").toLowerCase()?.match(search?.toLowerCase())
                ),
              }}
            />
          </div>
        </Container>
      </div>
    </>
  );
};

export default FullEmergencyFund;

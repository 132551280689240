import React, { useLayoutEffect, useRef, useState } from "react";

export const Dropdown = ({
  options,
  to_show,
  selected,
  setselected,
  placeholder,
  on,
  multiselect,
}) => {
  const [settings, setsettings] = useState({
    options: options || [],
    to_show: to_show || "",
    selected: selected || [],
    placeholder: placeholder || "",
    on: { click: on?.click || "" },
    multiselect: multiselect || false,
    dropdown_body_height: 0,
  });
  const [dropdown_isopen, setdropdown_isopen] = useState(false);
  const dropdown_body = useRef();
  useLayoutEffect(() => {
    setsettings((prev) => ({
      ...prev,
      dropdown_body_height:
        dropdown_body.current.getBoundingClientRect().height,
    }));
  }, [options]);
  useLayoutEffect(() => {
    window.addEventListener("click", () => {
      setdropdown_isopen(false);
    });
  }, []);
  return (
    <div
      className="dropdown"
      onClick={(e) => {
        e.stopPropagation();
        setdropdown_isopen((prev) => !prev);
      }}
    >
      <div className="dropdown_main">
        {selected?.length > 0 ? (
          <div className="selected">{selected.join(", ")}</div>
        ) : (
          <div className="placeholder">{placeholder}</div>
        )}
        <div className="arrow">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M20.0306 9.53104L12.5306 17.031C12.461 17.1008 12.3782 17.1561 12.2872 17.1938C12.1961 17.2316 12.0986 17.251 12 17.251C11.9014 17.251 11.8038 17.2316 11.7128 17.1938C11.6217 17.1561 11.539 17.1008 11.4694 17.031L3.96936 9.53104C3.82863 9.39031 3.74957 9.19944 3.74957 9.00042C3.74957 8.80139 3.82863 8.61052 3.96936 8.46979C4.1101 8.32906 4.30097 8.25 4.49999 8.25C4.69901 8.25 4.88988 8.32906 5.03062 8.46979L12 15.4401L18.9694 8.46979C19.0391 8.40011 19.1218 8.34483 19.2128 8.30712C19.3039 8.26941 19.4014 8.25 19.5 8.25C19.5985 8.25 19.6961 8.26941 19.7872 8.30712C19.8782 8.34483 19.9609 8.40011 20.0306 8.46979C20.1003 8.53947 20.1556 8.6222 20.1933 8.71324C20.231 8.80429 20.2504 8.90187 20.2504 9.00042C20.2504 9.09896 20.231 9.19654 20.1933 9.28759C20.1556 9.37863 20.1003 9.46136 20.0306 9.53104Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <div
        className={"dropdown_body" + (dropdown_isopen ? " active" : "")}
        style={{
          "--body_height": `${settings.dropdown_body_height}px`,
        }}
      >
        <div className="dropdown_body_main" ref={dropdown_body}>
          {options.map((el) => (
            <div className="option" onClick={() => setselected(el.key)}>
              {el.value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

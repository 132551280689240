import React from "react";
import Graph from "./common/Graph";
import { Chart } from "react-google-charts";
export const Testing = () => {
  const data = [
    [
      "Day",
      "Guardians of the Galaxy",
      // "The Avengers",
      // "Transformers: Age of Extinction",
    ],
    [1, 37.8],
    // [2, 30.9],
    // [3, 25.4, 57, 25.7],
    // [4, 11.7, 18.8, 10.5],
    // [5, 11.9, 17.6, 10.4],
    // [6, 8.8, 13.6, 7.7],
    // [7, 7.6, 12.3, 9.6],
    // [8, 12.3, 29.2, 10.6],
    // [9, 16.9, 42.9, 14.8],
    // [10, 12.8, 30.9, 11.6],
    // [11, 5.3, 7.9, 4.7],
    // [12, 6.6, 8.4, 5.2],
    // [13, 4.8, 6.3, 3.6],
    // [14, 4.2, 6.2, 3.4],
  ];

  const options = {
    chart: {
      title: "Box Office Earnings in First Two Weeks of Opening",
      subtitle: "in millions of dollars (USD)",
    },
  };
  return (
    <div>
      <div style={{ width: "50%" }}>
        <Graph
          data={{
            labels: ["January", "August", "September", "November"],
            datasets: [
              {
                label: "Grocery",
                data: [108, 180, 200, 432.25],
                color: "#0000ff",
                borderColor: "#0B6623",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
              },
              {
                color: "#0000ff",
                label: "Food",
                data: [400],
                borderColor: "#9DC184",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
              },
              {
                color: "#0000ff",
                label: "Eating Out",
                data: [163],
                borderColor: "#708238",
                backgroundColor: "rgba(255, 99, 132, 0.5)",
              },
            ],
          }}
        />
      </div>
      <Chart
        chartType="Line"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    </div>
  );
};

// import React, { useEffect, useState } from "react";

// var debtElimArr = [
//   { bal: 16403, mp: 555, ir: 5 },
//   { bal: 17060, mp: 931, ir: 5 },
//   { bal: 6082, mp: 780, ir: 9 },
//   { bal: 5595, mp: 981, ir: 12 },
//   { bal: 6763, mp: 512, ir: 29 },
//   { bal: 11247, mp: 581, ir: 15 },
//   { bal: 18352, mp: 776, ir: 8 },
//   { bal: 10835, mp: 689, ir: 9 },
// ];

// var avalancheSnowball = false;
function calculateAdditionalPayment(arr, additionalPayment) {
  var amount = additionalPayment;
  for (let a in arr) {
    if (arr[a].bal == 0) {
      amount += arr[a].mp;
    }
  }
  return amount;
}

function calculateTotalBal(arr, additionalPayment) {
  var amount = additionalPayment;
  for (let a in arr) {
    amount += arr[a].mp;
  }
  return amount;
}

function sort(arr, stat) {
  // console.log(stat);
  if (stat) {
    return arr.sort(sortAvalanche);
  }
  return arr.sort(sortSnowball);
}

function sortAvalanche(a, b) {
  if (a.ir == b.ir) {
    if (a.bal == b.bal) {
      return b.mp - a.mp;
    }
    return b.bal - a.bal;
  }
  return b.ir - a.ir;

  // return Number(a.bal) - Number(b.bal);
  // return b.bal - a.bal;
}

function sortSnowball(a, b) {
  if (a.bal == b.bal) {
    if (a.mp == b.mp) {
      return b.ir - a.ir;
    }
    return b.mp - a.mp;
  }
  return a.bal - b.bal;
}

function myFunction(arr_data, min_bal, avalanch) {
  var addPayment = min_bal;
  var valueArr = JSON.parse(arr_data);
  // console.log({ min_bal });
  var additionalPayment1 = calculateAdditionalPayment(valueArr, addPayment);
  var totalBal = calculateTotalBal(valueArr, addPayment);
  // console.log(valueArr);
  var monthsNeeded = 0;
  var totalInterest = 0;
  var firstDebtElim = 0;
  let debt_collection = [];
  var additionalPayment = addPayment;
  while (totalBal != additionalPayment1) {
    valueArr = sort(valueArr, avalanch);
    let zero_data = [];
    valueArr = [
      ...valueArr.filter((el) => {
        if (el.bal == 0) {
          zero_data.push(el);
          return false;
        }
        return true;
      }),
      ...zero_data,
    ];
    // console.table(JSON.stringify(valueArr));

    for (let a in valueArr) {
      let flag = false;
      if (a == 0) continue;
      if (firstDebtElim == 0) {
        if (valueArr[a].bal == 0) firstDebtElim = monthsNeeded + 1;
      }
      if (valueArr[a].bal == 0) {
        flag = true;
        valueArr[a].valuededuct = 0;
        additionalPayment += valueArr[a].mp;
      }
      var bal = valueArr[a].bal;
      var mp = valueArr[a].mp;
      var ir = valueArr[a].ir;
      valueArr[a].prevBal = bal;
      var afterMp = bal - mp;
      if (afterMp < 0) {
        valueArr[a].bal = 0;
        if (!flag) {
          additionalPayment += afterMp * -1;
        }
      } else {
        var afterIr = afterMp + (afterMp * ir * 0.01) / 12;
        totalInterest += (afterMp * ir * 0.01) / 12;
        valueArr[a].interestCut = (afterMp * ir * 0.01) / 12;
        valueArr[a].bal = afterIr;
        valueArr[a].valuededuct = mp;
      }
      // valueArr[a].rank = +a + 1;
    }
    bal = valueArr[0].bal;
    mp = valueArr[0].mp;
    ir = valueArr[0].ir;
    var valuededuct = mp + additionalPayment;
    afterMp = bal - valuededuct;
    if (afterMp < 0) {
      valueArr[0].bal = 0;
      additionalPayment = afterMp * -1;
      if (valueArr[1].bal > 0) {
        totalInterest -= valueArr[1].interestCut;
        valueArr[1].bal =
          valueArr[1].prevBal - valueArr[1].mp - additionalPayment;
        valueArr[1].valuededuct = valueArr[1].mp + additionalPayment;
        afterIr =
          valueArr[1].bal + (valueArr[1].bal * valueArr[1].ir * 0.01) / 12;
        valueArr[1].bal = afterIr;
        totalInterest += (valueArr[1].bal * valueArr[1].ir * 0.01) / 12;
      }
    } else {
      afterIr = afterMp + (afterMp * ir * 0.01) / 12;
      totalInterest += (afterMp * ir * 0.01) / 12;
      valueArr[0].bal = afterIr;
    }
    valueArr[0].valuededuct = valuededuct;
    // a: valueArr[0].valuededuct,
    // mp,
    // additionalPayment,
    // valueArr[0].rank = 1;
    // console.log(totalInterest);
    monthsNeeded++;
    zero_data = [];
    valueArr = [
      ...valueArr.filter((el) => {
        if (el.bal == 0) {
          zero_data.push(el);
          return false;
        }
        return true;
      }),
      ...zero_data,
    ];
    valueArr = valueArr.map((el, i) => ({
      ...el,
      rank: +i + 1,
      valuededuct: el.valuededuct,
    }));
    debt_collection.push(JSON.stringify(valueArr));
    // console.log(valueArr);
    additionalPayment = addPayment;
    additionalPayment1 = calculateAdditionalPayment(valueArr, addPayment);
    totalBal = calculateTotalBal(valueArr, addPayment);
    // console.log({
    //   a: debt_collection.slice(-2, -1),
    //   valuededuct,
    // });
  }

  // console.log("debt_collection : " + debt_collection);
  // console.log("Total Months Needed : " + monthsNeeded);
  // console.log("Test: ", totalInterest);
  // console.log("Total Interest Paid : " + totalInterest.toFixed(2));
  // console.log("Months to First Debt Elimination : " + firstDebtElim);
  return {
    monthsTillFirstDebtPaid1: firstDebtElim,
    monthsTillFirstDebtPaid2: firstDebtElim,
    approxIntrestPaid: totalInterest.toFixed(2),
    monthsTillPayoff: monthsNeeded,
    debt_collection: debt_collection.map((el) => JSON.parse(el)),
  };
}

// const DebtCalculator = ({ debt_array , calcuType}) => {
//   return myFunction(JSON.stringify([...debt_array]), calcuType)
// };

export default myFunction;

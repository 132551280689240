import React from "react";
import { useRef } from "react";
import Slider from "react-slick";
import { Images } from "../../image/Images";
import { nav_data } from "../../utils/utils";
// function SampleNextArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "red" }}
//       onClick={onClick}
//     >
//       asd
//     </div>
//   );
// }

// function SamplePrevArrow(props) {
//   const { className, style, onClick } = props;
//   return (
//     <div
//       className={className}
//       style={{ ...style, display: "block", background: "green" }}
//       onClick={onClick}
//     />
//   );
// }
export const NavSlider = ({ setroute, route }) => {
  const slider = useRef();
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    swipeToSlide: true,
    // slidesToScroll: 1,

    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  return (
    <div className="nav_slider">
      {false && (
        <div
          className="slider_btn prev"
          onClick={() => {
            slider.current.slickPrev();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M1.71938 11.469L10.7194 2.46899C10.8243 2.36398 10.958 2.29245 11.1035 2.26347C11.2491 2.23448 11.4 2.24933 11.5371 2.30615C11.6742 2.36296 11.7914 2.45918 11.8738 2.58263C11.9562 2.70607 12.0001 2.85119 12 2.99961V6.74961H17.25C17.4489 6.74961 17.6397 6.82863 17.7803 6.96928C17.921 7.10994 18 7.3007 18 7.49961V16.4996C18 16.6985 17.921 16.8893 17.7803 17.0299C17.6397 17.1706 17.4489 17.2496 17.25 17.2496H12V20.9996C12.0001 21.148 11.9562 21.2932 11.8738 21.4166C11.7914 21.54 11.6742 21.6363 11.5371 21.6931C11.4 21.7499 11.2491 21.7647 11.1035 21.7358C10.958 21.7068 10.8243 21.6352 10.7194 21.5302L1.71938 12.5302C1.64964 12.4606 1.59432 12.3779 1.55658 12.2868C1.51884 12.1958 1.49941 12.0982 1.49941 11.9996C1.49941 11.9011 1.51884 11.8035 1.55658 11.7124C1.59432 11.6214 1.64964 11.5386 1.71938 11.469ZM10.5 19.1893V16.4996C10.5 16.3007 10.579 16.1099 10.7197 15.9693C10.8603 15.8286 11.0511 15.7496 11.25 15.7496H16.5V8.24961H11.25C11.0511 8.24961 10.8603 8.1706 10.7197 8.02994C10.579 7.88929 10.5 7.69853 10.5 7.49961V4.80993L3.31031 11.9996L10.5 19.1893Z"
              fill="black"
            />
            <path
              className="arrow_line"
              d="M16.5 7.5V16.5C16.5 16.6989 16.579 16.8897 16.7197 17.0303C16.8603 17.171 17.0511 17.25 17.25 17.25C17.4489 17.25 17.6397 17.171 17.7803 17.0303C17.921 16.8897 18 16.6989 18 16.5V7.5C18 7.30109 17.921 7.11032 17.7803 6.96967C17.6397 6.82902 17.4489 6.75 17.25 6.75C17.0511 6.75 16.8603 6.82902 16.7197 6.96967C16.579 7.11032 16.5 7.30109 16.5 7.5Z"
              fill="black"
            />
          </svg>
        </div>
      )}
      <Slider {...settings} ref={slider} className="navigation_group">
        {/* <div className="navigation_group"> */}
        {nav_data.map((el) => (
          <div className="nav_main" onClick={() => setroute(el.to)}>
            <div className={"nav_item" + (route == el.to ? " active" : "")}>
              <div className="nav_item_icon">
                <Images get={el.icon} />
              </div>
              <div className="nav_txt">
                <div>{el.name}</div>
              </div>
              {/* <div className="nav_goto"
               onClick={() => setroute(el.to)}>
                <Images get={"line_arrow_right"} />
              </div> */}
            </div>
          </div>
        ))}
        {/* </div> */}
      </Slider>
      {false && (
        <div
          className="slider_btn next"
          onClick={() => {
            slider.current.slickNext();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M1.71938 11.469L10.7194 2.46899C10.8243 2.36398 10.958 2.29245 11.1035 2.26347C11.2491 2.23448 11.4 2.24933 11.5371 2.30615C11.6742 2.36296 11.7914 2.45918 11.8738 2.58263C11.9562 2.70607 12.0001 2.85119 12 2.99961V6.74961H17.25C17.4489 6.74961 17.6397 6.82863 17.7803 6.96928C17.921 7.10994 18 7.3007 18 7.49961V16.4996C18 16.6985 17.921 16.8893 17.7803 17.0299C17.6397 17.1706 17.4489 17.2496 17.25 17.2496H12V20.9996C12.0001 21.148 11.9562 21.2932 11.8738 21.4166C11.7914 21.54 11.6742 21.6363 11.5371 21.6931C11.4 21.7499 11.2491 21.7647 11.1035 21.7358C10.958 21.7068 10.8243 21.6352 10.7194 21.5302L1.71938 12.5302C1.64964 12.4606 1.59432 12.3779 1.55658 12.2868C1.51884 12.1958 1.49941 12.0982 1.49941 11.9996C1.49941 11.9011 1.51884 11.8035 1.55658 11.7124C1.59432 11.6214 1.64964 11.5386 1.71938 11.469ZM10.5 19.1893V16.4996C10.5 16.3007 10.579 16.1099 10.7197 15.9693C10.8603 15.8286 11.0511 15.7496 11.25 15.7496H16.5V8.24961H11.25C11.0511 8.24961 10.8603 8.1706 10.7197 8.02994C10.579 7.88929 10.5 7.69853 10.5 7.49961V4.80993L3.31031 11.9996L10.5 19.1893Z"
              fill="black"
            />
            <path
              className="arrow_line"
              d="M16.5 7.5V16.5C16.5 16.6989 16.579 16.8897 16.7197 17.0303C16.8603 17.171 17.0511 17.25 17.25 17.25C17.4489 17.25 17.6397 17.171 17.7803 17.0303C17.921 16.8897 18 16.6989 18 16.5V7.5C18 7.30109 17.921 7.11032 17.7803 6.96967C17.6397 6.82902 17.4489 6.75 17.25 6.75C17.0511 6.75 16.8603 6.82902 16.7197 6.96967C16.579 7.11032 16.5 7.30109 16.5 7.5Z"
              fill="black"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

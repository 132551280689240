import React, { useEffect, useRef, useState } from "react";
import { Container } from "../Container";
// import { Table } from "../common/Table";
import Switch from "../common/Switch";
import { date_formatter, getLastMonthsDates } from "../common/Date";
// import DebtCalculator from "../common/DebtCalculator";
import myFunction from "../common/DebtCalculator";

export const DECTable = ({ tclass, theader, tbody }) => {
  // console.log(tbody);
  return (
    <div className={`table ${tclass}`}>
      <div className="table_main">
        <div className="table_header">
          {theader.map((el, i) => (
            <div className={`col${i + 1}`}>{el}</div>
          ))}
        </div>
        <div className="table_body">
          <div className="header_bg"></div>
          {tbody?.map((el, i) => (
            <div
              className={
                "row " +
                ((Number(el[1].slice(1)) * Number(el[3].slice(0, -1)) * 0.01) /
                  12 >
                Number(el[2].slice(1))
                  ? " inactive"
                  : "")
              }
            >
              {/* {console.log(
                Number(el[1].slice(1)) *  Number(el[3].slice(0, -1)) * 0.01 > 
                  Number(el[2].slice(1)) , Number(el[1].slice(1))
              )} */}
              {
                // el?
                el.map((el1, i1) => (
                  <div className={`col${i1 + 1}`}>{el1}</div>
                ))
                // : "No Data"
              }
            </div>
          ))}
          {tbody?.length == 0 || !tbody ? (
            <div className="no_data">No Data</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

const DebtEliminationCalculator = ({ setroute, route, user_data }) => {
  const [switchData, setSwitchData] = useState(false);
  const table1 = useRef(null);
  const [height_table, setHeight_table] = useState(0);
  const [numberOfMonths, setnumberOfMonths] = useState(0);
  const [DataCollection, setDataCollection] = useState({
    monthsTillFirstDebtPaid1: 0,
    monthsTillFirstDebtPaid2: 0,
    approxIntrestPaid: 0,
    monthsTillPayoff: 0,
    debt_collection: [],
  });
  useEffect(() => {
    if (table1.current) {
      const divHeight = table1.current.clientHeight;
      setHeight_table(divHeight);
      // console.log("Div height:", divHeight);
    }
  }, []);

  // const numberOfMonths = 3;
  const lastMonthsDates = getLastMonthsDates(numberOfMonths)?.map((el, i) =>
    date_formatter(el, "m_long  year_long")
  );

  const [Dates, setDates] = useState("All");
  // const dum = user_data.debt_elimination_calculator?.trans_data?.slice(1);
  // console.log(lastMonthsDates.indexOf(Dates), Dates, lastMonthsDates);
  useEffect(() => {
    if (Dates == "All") {
      // const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      // };
    }
  }, [Dates]);
  useEffect(() => {
    setnumberOfMonths(DataCollection?.debt_collection?.length);
  }, [user_data, DataCollection]);
  // <DebtCalculator
  //   debt_array={user_data.debt_elimination_calculator.trans_data}
  //   calcuType={!switchData}
  // />
  useEffect(() => {
    if (user_data.debt_elimination_calculator.trans_data.length > 0) {
      setDataCollection(
        myFunction(
          JSON.stringify([
            ...user_data.debt_elimination_calculator.trans_data.map((el) => ({
              bal: el.balance,
              mp: el.monthly_payment,
              ir: el.interest_rate,
              value: el.card_name,
            })),
          ]),
          user_data?.debt_elimination_calculator?.data || 0,
          !switchData
        )
      );
    }
  }, [switchData, user_data]);

  // console.log({ DataCollection });

  // return;
  return (
    <Container>
      <div className="debt_e_calculator">
        <div className="debt_e_calculator_switch">
          <DECTable
            {...{
              tclass: "default_table",
              theader: [
                "Debt type",
                "Balance",
                "Monthly payment",
                "Interest rate",
                // "Ranking",
              ],
              tbody:
                Dates == "All"
                  ? user_data?.debt_elimination_calculator?.trans_data
                    ? user_data?.debt_elimination_calculator?.trans_data.map(
                        (el) => [
                          el.card_name,
                          `$${el.balance < 0 ? "0.00" : el.balance}`,
                          `$${el.monthly_payment}`,
                          `${el.interest_rate}%`,
                          // <div>{el.rank}</div>,
                        ]
                      )
                    : []
                  : DataCollection?.debt_collection[Dates]?.map((el) => [
                      el.value,
                      `$${el.bal < 0 ? "0.00" : el.bal}`,
                      `$${el.mp}`,
                      `${el.ir}%`,
                      <div>{el.rank}</div>,
                    ]),
              // d: user_data.debt_elimination_calculator.trans_data[
              //   Dates == "All" ? 0 : Dates
              // ].map((el) => [
              //   <div>{el.card_name}</div>,
              //   <div>{el.balance}</div>,
              //   <div>{el.monthly_payment}</div>,
              //   <div>{el.interest_rate}</div>,
              //   <div>5</div>,
              // ]),
            }}
          />
          <Switch
            custclass=""
            page_values={{
              left: "Snowball ranking",
              right: "Avalanche ranking ",
            }}
            {...{
              onevent: (is_active) => {
                setSwitchData(is_active);
              },
            }}
          />
        </div>
        <div className="debt_e_calculator_title">
          <div className="de_title_1">
            Additional payment towards debt : $
            {user_data?.debt_elimination_calculator?.data || 0}
          </div>
          <div className="de_title_2">
            {!switchData ? "Avalanche" : "Snowball"} method till first debt
            paid:{" "}
            {isFinite(DataCollection.monthsTillFirstDebtPaid1)
              ? DataCollection.monthsTillFirstDebtPaid1
              : "∞"}{" "}
            months
            {/* : "Connot be paid"} */}
            {/* {user_data.debt_elimination_calculator.first_debt_payed} */}
          </div>
          <div className="de_title_3">
            {/* {console.log(DataCollection.approxIntrestPaid)}` */}
            Approximate interest paid: ${DataCollection.approxIntrestPaid}
          </div>
          <div className="de_title_4">
            *{!switchData ? "Avalanche" : "Snowball"} method months till payoff:{" "}
            {/* {user_data.debt_elimination_calculator.months_till_payoff} */}
            {DataCollection.monthsTillPayoff} months
          </div>
        </div>
        <div className="de_body">
          <div className="de_left">
            <div className="de_left_date_set">
              <div className="de_left_header">Months</div>
              <div
                className="de_left_body"
                // style={{ maxHeight: height_table - "53" || 0 }}
              >
                <div
                  className={
                    "month_single " + (Dates == "All" ? "date_active" : "")
                  }
                  onClick={() => setDates("All")}
                >
                  All
                </div>
                {lastMonthsDates?.map((el, i) => (
                  <div
                    key={i}
                    className={
                      "month_single " + (Dates == i ? " date_active" : "")
                    }
                    onClick={() => {
                      setDates(i);
                    }}
                  >
                    {el}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="de_right">
            <div ref={table1}>
              <DECTable
                {...{
                  tclass: "",
                  theader: [
                    "Debt type",
                    "Monthly payment",
                    "Interest rate",
                    "Balance",
                    "Ranking",
                  ],
                  tbody: DataCollection?.debt_collection[
                    Dates == "All" ? 0 : Dates
                  ]?.map((el) => [
                    el.value,
                    `$${(el.valuededuct || 0).toFixed(2)}`,
                    `${el.ir}%`,
                    `$${el.bal < 0 ? "0.00" : el.bal.toFixed(2)}`,
                    <div>{el.rank}</div>,
                  ]),
                  // d: user_data.debt_elimination_calculator.trans_data[
                  //   Dates == "All" ? 0 : Dates
                  // ].map((el) => [
                  //   <div>{el.card_name}</div>,
                  //   <div>{el.balance}</div>,
                  //   <div>{el.monthly_payment}</div>,
                  //   <div>{el.interest_rate}</div>,
                  //   <div>5</div>,
                  // ]),
                }}
              />

              <div className="table_date">
                {lastMonthsDates[Dates == "All" ? 0 : Dates]}
              </div>
            </div>
            <div>
              {DataCollection?.debt_collection
                ?.slice(1)
                ?.filter((el2) => {
                  if (Dates == "All") {
                    return el2;
                  }
                })
                ?.map((el, i) => (
                  <div className="all_table">
                    <DECTable
                      {...{
                        tclass: "",
                        theader: [
                          "Debt type",
                          "Monthly payment",
                          "Interest rate",
                          "Balance",
                          "Ranking",
                        ],
                        tbody: el?.map((el) => [
                          // <div>{el.value}</div>,
                          // <div>${el.bal < 0 ? "0.00" : el.bal}</div>,
                          // <div>${el.mp}</div>,
                          // <div>{el.ir}%</div>,
                          // <div>{el.rank}</div>,
                          el.value,

                          `$${(el.valuededuct || 0).toFixed(2)}`,
                          `${el.ir}%`,
                          `$${el.bal < 0 ? "0.00" : el.bal.toFixed(2)}`,
                          <div>{el.rank}</div>,
                        ]),

                        // el.map((el) => [
                        //   <div>{el.card_name}</div>,
                        //   <div>{el.balance}</div>,
                        //   <div>{el.monthly_payment}</div>,
                        //   <div>{el.interest_rate}</div>,
                        //   <div>5</div>,
                        // ]),
                      }}
                    />
                    <div className="table_date2">{lastMonthsDates[i + 1]}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default DebtEliminationCalculator;

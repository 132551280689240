import axios from "axios";
import DollerConverter from "../components/common/DollerConverter";
import DebtCalculator from "../components/common/DebtCalculator";

const base_url = `https://script.google.com/macros/s/AKfycbxijK76BSJLn5RFdprd-iFbncoKZXQ1lKjqqVCkWtSnTlvXw8Zas8m7q4N1IjPFm68lIw/exec`;

export const colordata = [
  "#0B6623",
  "#9DC184",
  "#708238",
  "#C7EA46",
  "#3F714C",
  "#00A86B",
  "#8E9778",
  "#8A9A5B",
  "#99FA99",
  "#01796F",
  "#D0F0C1",
  "#01A573",
  "#4B5320",
  "#51C878",
  "#4CBB17",
  "#39FF14",
  "#434C37",
  "#043927",
  "#679267",
  "#2F8B58",
];
export const colordata1 = [
  "#F00",
  "#0F0",
  "#00F",
  "#FFFF00",
  "#FF00FF",
  "#00FFFF",
  "#FFA500",
  "#800080",
  "#008000",
  "#800000",
  "#008080",
  "#FFC0CB",
  "#A6A67B",
  "#F0E68C",
  "#00CED1",
  "#FF69B4",
  "#B35656",
  "#6A5ACD",
  "#8B008B",
  "#FF4500",
];

export const GetData = (callback) => {
  // axios
  //   .post(`${base_url}`,{
  //     userEmail: "quality+admin@modernizingprocesses.com",
  //     currentMonth: "July",
  //     currentYear: "2023",
  //   })
  //   .then((response) => response.data)
  //   .then((response) => {
  //     if (response.err) {
  //       return callback({
  //         status: false,
  //       });
  //     }

  //     return callback({
  //       status: true,
  //       response,
  //     });
  //   });
  let currentData = window.location.search.slice(1).split(":::");
  let data = {
    //  JSON.stringify({
    userEmail: currentData[0],
    currentMonth: currentData[1],
    currentYear: currentData[2],
  };
  if (!data.userEmail || !data.currentMonth || !data.currentYear) {
    return;
  }
  // });
  // console.log(data);
  var response = {
    full_budget: {
      income_type: [
        {
          expenseName: "Hourly",
          expenseAmount: 60,
        },
        {
          expenseName: "Others",
          expenseAmount: 55,
        },
        {
          expenseName: "Total income",
          expenseAmount: 115,
        },
      ],
      monthly_fixed_expenses: [
        {
          expenseName: "Housing",
          expenseAmount: 5500,
        },
        {
          expenseName: "Work transit",
          expenseAmount: 500,
        },
        {
          expenseName: "Maintenance",
          expenseAmount: 200,
        },
        {
          expenseName: "Loan",
          expenseAmount: 6000,
        },
        {
          expenseName: "Total income",
          expenseAmount: 43900,
        },
      ],
      monthly_variable_expenses: [
        {
          expenseName: "Credit Card Bill",
          expenseAmount: 4500,
        },
        {
          expenseName: "Electricity Bill",
          expenseAmount: 300,
        },
        {
          expenseName: "Water Bill",
          expenseAmount: 45,
        },
        {
          expenseName: "Total income",
          expenseAmount: 24135,
        },
      ],
      monthly_flexible_expenses: [
        {
          expenseName: "Hobbies",
          expenseAmount: 80,
        },
        {
          expenseName: "Entertainment",
          expenseAmount: 25,
        },
        {
          expenseName: "Car Lease",
          expenseAmount: 620,
        },
        {
          expenseName: "Hobbies",
          expenseAmount: 80,
        },
        {
          expenseName: "Total income",
          expenseAmount: 4415,
        },
      ],
    },
    debt_elimination_calculator: [
      {
        name: "Car Loan",
        balance: 45000,
        monthly_payment: 150,
        interest_rate: 6.9,
      },
      {
        name: "Student Loan",
        balance: 58000,
        monthly_payment: 1000,
        interest_rate: 3,
      },
      {
        name: "Car Payments",
        balance: 5000,
        monthly_payment: 150,
        interest_rate: 2,
      },
    ],
    digital_envelope: [
      {
        month: "July",
        category: "Eating Out",
        budgetAmount: 20,
        purchase: 0,
        total: 20,
      },
      {
        month: "July",
        category: "Misc",
        budgetAmount: 230,
        purchase: 0,
        total: 230,
      },
      {
        month: "July",
        category: "Grocery",
        budgetAmount: 450,
        purchase: 140,
        total: 310,
      },
      {
        month: "June",
        category: "Eating Out",
        budgetAmount: 20,
        purchase: 5,
        total: 15,
      },
      {
        month: "June",
        category: "Misc",
        budgetAmount: 230,
        purchase: 50,
        total: 180,
      },
      {
        month: "June",
        category: "Grocery",
        budgetAmount: 450,
        purchase: 20,
        total: 430,
      },
      {
        month: "May",
        category: "Eating Out",
        budgetAmount: 500,
        purchase: 120,
        total: 380,
      },
      {
        month: "May",
        category: "Misc",
        budgetAmount: 230,
        purchase: 50,
        total: 180,
      },
      {
        month: "May",
        category: "Grocery",
        budgetAmount: 450,
        purchase: 0,
        total: 450,
      },
    ],
    full_emergency_fund: {
      fixedExpense_Month: 43900,
      fixedExpense_3Month: 131700,
      fixedExpense_6Month: 263400,
      fixedExpense_9Month: 395100,
      fixedExpense_12Month: 526800,
      variableExpense_Month: 24135,
      variableExpense_3Month: 72405,
      variableExpense_6Month: 144810,
      variableExpense_9Month: 217215,
      variableExpense_12Month: 289620,
      flexibleExpense_Month: 4415,
      flexibleExpense_3Month: 13245,
      flexibleExpense_6Month: 26490,
      flexibleExpense_9Month: 39735,
      flexibleExpense_12Month: 52980,
      monthly: 72450,
      months_3: 217350,
      months_6: 434700,
      months_9: 652050,
      months_12: 869400,
    },
    monthly_affirmation: {
      income_type: [
        {
          hourly: 60,
          other: 55,
          monthYear: "July 2023",
        },
        {
          hourly: 55,
          other: 55,
          monthYear: "June 2023",
        },
        {
          hourly: 55,
          other: 60,
          monthYear: "May 2023",
        },
      ],
      monthly_fixed_expenses: [
        {
          expenseName: "Housing",
          monthYear: "July 2023",
          expenseAmount: 5500,
        },
        {
          expenseName: "Work transit",
          monthYear: "July 2023",
          expenseAmount: 500,
        },
        {
          expenseName: "Maintenance",
          monthYear: "July 2023",
          expenseAmount: 200,
        },
        {
          expenseName: "Loan",
          monthYear: "July 2023",
          expenseAmount: 6000,
        },
        {
          expenseName: "Housing",
          monthYear: "June 2023",
          expenseAmount: 2000,
        },
        {
          expenseName: "Work transit",
          monthYear: "June 2023",
          expenseAmount: 3500,
        },
        {
          expenseName: "Maintenance",
          monthYear: "June 2023",
          expenseAmount: 1200,
        },
        {
          expenseName: "Loan",
          monthYear: "June 2023",
          expenseAmount: 6800,
        },
        {
          expenseName: "Housing",
          monthYear: "May 2023",
          expenseAmount: 7500,
        },
        {
          expenseName: "Work transit",
          monthYear: "May 2023",
          expenseAmount: 2500,
        },
        {
          expenseName: "Maintenance",
          monthYear: "May 2023",
          expenseAmount: 5200,
        },
        {
          expenseName: "Loan",
          monthYear: "May 2023",
          expenseAmount: 3000,
        },
      ],
      monthly_variable_expenses: [
        {
          expenseName: "Credit Card Bill",
          monthYear: "July 2023",
          expenseAmount: 4500,
        },
        {
          expenseName: "Electricity Bill",
          monthYear: "July 2023",
          expenseAmount: 300,
        },
        {
          expenseName: "Water Bill",
          monthYear: "July 2023",
          expenseAmount: 45,
        },
        {
          expenseName: "Credit Card Bill",
          monthYear: "June 2023",
          expenseAmount: 5500,
        },
        {
          expenseName: "Electricity Bill",
          monthYear: "June 2023",
          expenseAmount: 3300,
        },
        {
          expenseName: "Water Bill",
          monthYear: "June 2023",
          expenseAmount: 545,
        },
        {
          expenseName: "Credit Card Bill",
          monthYear: "May 2023",
          expenseAmount: 6500,
        },
        {
          expenseName: "Electricity Bill",
          monthYear: "May 2023",
          expenseAmount: 3300,
        },
        {
          expenseName: "Water Bill",
          monthYear: "May 2023",
          expenseAmount: 145,
        },
      ],
      monthly_flexible_expenses: [
        {
          expenseName: "Hobbies",
          monthYear: "July 2023",
          expenseAmount: 80,
        },
        {
          expenseName: "Entertainment",
          monthYear: "July 2023",
          expenseAmount: 25,
        },
        {
          expenseName: "Car Lease",
          monthYear: "July 2023",
          expenseAmount: 620,
        },
        {
          expenseName: "Hobbies",
          monthYear: "July 2023",
          expenseAmount: 80,
        },
        {
          expenseName: "Hobbies",
          monthYear: "June 2023",
          expenseAmount: 180,
        },
        {
          expenseName: "Entertainment",
          monthYear: "June 2023",
          expenseAmount: 225,
        },
        {
          expenseName: "Car Lease",
          monthYear: "June 2023",
          expenseAmount: 620,
        },
        {
          expenseName: "Hobbies",
          monthYear: "June 2023",
          expenseAmount: 380,
        },
        {
          expenseName: "Hobbies",
          monthYear: "May 2023",
          expenseAmount: 480,
        },
        {
          expenseName: "Entertainment",
          monthYear: "May 2023",
          expenseAmount: 425,
        },
        {
          expenseName: "Car Lease",
          monthYear: "May 2023",
          expenseAmount: 620,
        },
        {
          expenseName: "Hobbies",
          monthYear: "May 2023",
          expenseAmount: 680,
        },
        {
          expenseName: "testHobbies",
          monthYear: "August 2022",
          expenseAmount: 680,
        },
      ],
    },
    week_saving_challenge: {
      savinggoal: 12000,
      savingtotal: 0,
      perweekjson: [
        {
          weeknumber: 1,
          weeklydeposit: 8.71,
          balance: 8.71,
        },
        {
          weeknumber: 2,
          weeklydeposit: 17.42,
          balance: 26.130000000000003,
        },
        {
          weeknumber: 3,
          weeklydeposit: 26.12,
          balance: 52.25,
        },
        {
          weeknumber: 4,
          weeklydeposit: 34.83,
          balance: 87.08,
        },
        {
          weeknumber: 5,
          weeklydeposit: 43.54,
          balance: 130.62,
        },
        {
          weeknumber: 6,
          weeklydeposit: 52.25,
          balance: 182.87,
        },
        {
          weeknumber: 7,
          weeklydeposit: 60.96,
          balance: 243.83,
        },
        {
          weeknumber: 8,
          weeklydeposit: 69.67,
          balance: 313.5,
        },
        {
          weeknumber: 9,
          weeklydeposit: 78.37,
          balance: 391.87,
        },
        {
          weeknumber: 10,
          weeklydeposit: 87.08,
          balance: 478.95,
        },
        {
          weeknumber: 11,
          weeklydeposit: 95.79,
          balance: 574.74,
        },
        {
          weeknumber: 12,
          weeklydeposit: 104.5,
          balance: 679.24,
        },
        {
          weeknumber: 13,
          weeklydeposit: 113.21,
          balance: 792.45,
        },
        {
          weeknumber: 14,
          weeklydeposit: 121.92,
          balance: 914.37,
        },
        {
          weeknumber: 15,
          weeklydeposit: 130.62,
          balance: 1044.99,
        },
        {
          weeknumber: 16,
          weeklydeposit: 139.33,
          balance: 1184.32,
        },
        {
          weeknumber: 17,
          weeklydeposit: 148.04,
          balance: 1332.36,
        },
        {
          weeknumber: 18,
          weeklydeposit: 156.75,
          balance: 1489.11,
        },
        {
          weeknumber: 19,
          weeklydeposit: 165.46,
          balance: 1654.57,
        },
        {
          weeknumber: 20,
          weeklydeposit: 174.17,
          balance: 1828.74,
        },
        {
          weeknumber: 21,
          weeklydeposit: 182.87,
          balance: 2011.6100000000001,
        },
        {
          weeknumber: 22,
          weeklydeposit: 191.58,
          balance: 2203.19,
        },
        {
          weeknumber: 23,
          weeklydeposit: 200.29,
          balance: 2403.48,
        },
        {
          weeknumber: 24,
          weeklydeposit: 209,
          balance: 2612.48,
        },
        {
          weeknumber: 25,
          weeklydeposit: 217.71,
          balance: 2830.19,
        },
        {
          weeknumber: 26,
          weeklydeposit: 226.42,
          balance: 3056.61,
        },
        {
          weeknumber: 27,
          weeklydeposit: 235.12,
          balance: 3291.73,
        },
        {
          weeknumber: 28,
          weeklydeposit: 243.83,
          balance: 3535.56,
        },
        {
          weeknumber: 29,
          weeklydeposit: 252.54,
          balance: 3788.1,
        },
        {
          weeknumber: 30,
          weeklydeposit: 261.25,
          balance: 4049.35,
        },
        {
          weeknumber: 31,
          weeklydeposit: 269.96,
          balance: 4319.3099999999995,
        },
        {
          weeknumber: 32,
          weeklydeposit: 278.66,
          balance: 4597.969999999999,
        },
        {
          weeknumber: 33,
          weeklydeposit: 287.37,
          balance: 4885.339999999999,
        },
        {
          weeknumber: 34,
          weeklydeposit: 296.08,
          balance: 5181.419999999999,
        },
        {
          weeknumber: 35,
          weeklydeposit: 304.79,
          balance: 5486.209999999999,
        },
        {
          weeknumber: 36,
          weeklydeposit: 313.5,
          balance: 5799.709999999999,
        },
        {
          weeknumber: 37,
          weeklydeposit: 322.21,
          balance: 6121.919999999999,
        },
        {
          weeknumber: 38,
          weeklydeposit: 330.91,
          balance: 6452.829999999999,
        },
        {
          weeknumber: 39,
          weeklydeposit: 339.62,
          balance: 6792.449999999999,
        },
        {
          weeknumber: 40,
          weeklydeposit: 348.33,
          balance: 7140.779999999999,
        },
        {
          weeknumber: 41,
          weeklydeposit: 357.04,
          balance: 7497.819999999999,
        },
        {
          weeknumber: 42,
          weeklydeposit: 365.75,
          balance: 7863.569999999999,
        },
        {
          weeknumber: 43,
          weeklydeposit: 374.46,
          balance: 8238.029999999999,
        },
        {
          weeknumber: 44,
          weeklydeposit: 383.16,
          balance: 8621.189999999999,
        },
        {
          weeknumber: 45,
          weeklydeposit: 391.87,
          balance: 9013.06,
        },
        {
          weeknumber: 46,
          weeklydeposit: 400.58,
          balance: 9413.64,
        },
        {
          weeknumber: 47,
          weeklydeposit: 409.29,
          balance: 9822.93,
        },
        {
          weeknumber: 48,
          weeklydeposit: 418,
          balance: 10240.93,
        },
        {
          weeknumber: 49,
          weeklydeposit: 426.71,
          balance: 10667.64,
        },
        {
          weeknumber: 50,
          weeklydeposit: 435.41,
          balance: 11103.05,
        },
        {
          weeknumber: 51,
          weeklydeposit: 444.12,
          balance: 11547.17,
        },
        {
          weeknumber: 52,
          weeklydeposit: 452.83,
          balance: 12000,
        },
      ],
    },
  };

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `https://script.google.com/macros/s/AKfycbwD2E0OChu3XhJqsTFS1dKpU2_akTU_lSfyy1d_TbrLWjPPFBkFJE_eWI400qsLZv73Ng/exec?${new URLSearchParams(
      data
    ).toString()}`,
    // headers: {
    //   "Content-Type": "application/json",
    // },
    // data: data,
  };
  // console.log(config.url);
  axios
    .request(config)
    .then((res) => {
      let response = res.data;
      const page_data = {
        full_budget: {
          income_type: [
            ...response?.full_budget?.income_type?.map((el, i) =>
              i === response?.full_budget?.income_type.length - 1
                ? [
                    <span className="color_base_primary">
                      {el?.expenseName}
                    </span>,
                    <span className="color_base_primary">
                      <DollerConverter value={el?.expenseAmount} />
                      {/* ${el?.expenseAmount} */}
                    </span>,
                  ]
                : [
                    `${el?.expenseName}`,
                    <DollerConverter value={el?.expenseAmount} />,
                  ]
            ),
          ],
          monthly_fixed_expenses: [
            ...response?.full_budget?.monthly_fixed_expenses?.map((el, i) =>
              i === response?.full_budget?.monthly_fixed_expenses.length - 1
                ? [
                    <span className="color_base_primary">
                      {el?.expenseName}
                    </span>,
                    <span className="color_base_primary">
                      <DollerConverter value={el?.expenseAmount} />
                    </span>,
                  ]
                : [
                    el?.expenseName,
                    <DollerConverter value={el?.expenseAmount} />,
                  ]
            ),
            // ["#REF!", "#REF!"],
            // ["#REF!", "#REF!"],
            // ["#REF!", "#REF!"],
            // ["#REF!", "#REF!"],
            // ["#REF!", "#REF!"],
            // [
            //   <span className="color_base_primary">Total income</span>,
            //   <span className="color_base_primary">#REF!</span>,
            // ],
          ],
          monthly_variable_expenses: [
            ...response?.full_budget?.monthly_variable_expenses?.map((el, i) =>
              i === response?.full_budget?.monthly_variable_expenses.length - 1
                ? [
                    <span className="color_base_primary">
                      {el?.expenseName}
                    </span>,
                    <span className="color_base_primary">
                      <DollerConverter value={el?.expenseAmount} />
                    </span>,
                  ]
                : [
                    el?.expenseName,
                    <DollerConverter value={el?.expenseAmount} />,
                  ]
            ),
            // ["#REF!", "#REF!"],
            // ["#REF!", "#REF!"],
            // ["#REF!", "#REF!"],
            // ["#REF!", "#REF!"],
            // ["#REF!", "#REF!"],
            // [
            //   <span className="color_base_primary">Total income</span>,
            //   <span className="color_base_primary">#REF!</span>,
            // ],
          ],
          monthly_flexible_expenses: [
            ...response?.full_budget?.monthly_flexible_expenses?.map((el, i) =>
              i === response?.full_budget?.monthly_flexible_expenses.length - 1
                ? [
                    <span className="color_base_primary">
                      {el?.expenseName}
                    </span>,
                    <span className="color_base_primary">
                      <DollerConverter value={el?.expenseAmount} />
                    </span>,
                  ]
                : [
                    el?.expenseName,
                    <DollerConverter value={el?.expenseAmount} />,
                  ]
            ),
            // ["Eating out", "$0"],
            // ["Gym membership", "$0"],
            // ["Hobbies", ""],
            // ["Entertainment", ""],
            // ["Coble", ""],
            // ["Streaming", ""],
            // ["OPEN", "$0"],
            // ["OPEN", ""],
            // ["OPEN", "$0"],
            // ["OPEN", ""],
            // [
            //   <span className="color_base_primary">Total flexible expenses</span>,
            //   <span className="color_base_primary">#0</span>,
            // ],
          ],
          full_budget: [
            [
              "Total income",
              <DollerConverter
                value={Number(
                  response.full_budget.income_type
                    .slice(-1)
                    .map((el) => el.expenseAmount)
                )}
              />,
            ],
            [
              "Total expense",
              <DollerConverter
                value={
                  "-" +
                  (Number(
                    response.full_budget.monthly_fixed_expenses
                      .slice(-1)
                      .map((el) => el.expenseAmount)
                  ) +
                    Number(
                      response.full_budget.monthly_variable_expenses
                        .slice(-1)
                        .map((el) => el.expenseAmount)
                    ) +
                    Number(
                      response.full_budget.monthly_flexible_expenses
                        .slice(-1)
                        .map((el) => el.expenseAmount)
                    ))
                }
              />,
            ],
            [
              "Discretionary income",
              <DollerConverter
                value={
                  Number(
                    response.full_budget.income_type
                      .slice(-1)
                      .map((el) => el.expenseAmount)
                  ) -
                  (Number(
                    response.full_budget.monthly_fixed_expenses
                      .slice(-1)
                      .map((el) => el.expenseAmount)
                  ) +
                    Number(
                      response.full_budget.monthly_variable_expenses
                        .slice(-1)
                        .map((el) => el.expenseAmount)
                    ) +
                    Number(
                      response.full_budget.monthly_flexible_expenses
                        .slice(-1)
                        .map((el) => el.expenseAmount)
                    ))
                }
              />,
            ],
          ],
          barebones_budget: [
            [
              "Total income",
              <DollerConverter
                value={Number(
                  response.full_budget.income_type
                    .slice(-1)
                    .map((el) => el.expenseAmount)
                )}
              />,
            ],
            [
              "Total expense",
              <DollerConverter
                value={
                  "-" +
                  (Number(
                    response.full_budget.monthly_fixed_expenses
                      .slice(-1)
                      .map((el) => el.expenseAmount)
                  ) +
                    Number(
                      response.full_budget.monthly_variable_expenses
                        .slice(-1)
                        .map((el) => el.expenseAmount)
                    ))
                }
              />,
            ],
            [
              "Discretionary income",
              <DollerConverter
                value={
                  Number(
                    response.full_budget.income_type
                      .slice(-1)
                      .map((el) => el.expenseAmount)
                  ) -
                  (Number(
                    response.full_budget.monthly_fixed_expenses
                      .slice(-1)
                      .map((el) => el.expenseAmount)
                  ) +
                    Number(
                      response.full_budget.monthly_variable_expenses
                        .slice(-1)
                        .map((el) => el.expenseAmount)
                    ))
                }
              />,
            ],
          ],
        },
        debt_elimination_calculator: {
          // additional_payment_toward_debt: 0,
          // first_debt_payed: <DebtCalculator debtcalc_type={"Firstdebtpayed"} debt_array={response?.debt_elimination_calculator}/> ,
          // approximate_interst_paid:  <DebtCalculator debtcalc_type={"Interestpayed"} debt_array={response?.debt_elimination_calculator}/>,
          // months_till_payoff: "27 months",
          data: response?.debt_elimination_calculator[0].additional_payment,
          trans_data: response?.debt_elimination_calculator
            ?.slice(1)
            .map((el) => ({
              card_name: el.name,
              balance: el.balance,
              monthly_payment: el.monthly_payment,
              interest_rate: el.interest_rate,
            })),
          // {
          //   name: "Car Loan",
          //   balance: 45000,
          //   monthly_payment: 150,
          //   interest_rate: 6.9,
          // },
        },
        digital_envelope: [
          // data: [
          ...[response?.digital_envelope].reduce((prev, cur) => {
            let tempdata = cur;
            let newArray = [];
            let newObj = {};
            // let uniqueCateg = [...new Set(cur.map((el)=>el.category))].map((el) => newObj[el] = []);
            cur.map((el) => {
              if (newObj[el.category]) {
                newObj[el.category] = [
                  ...newObj[el.category],
                  {
                    month: el.month,
                    year: el.year,
                    budget_amount: el.budgetAmount,
                    purchases: el.purchase,
                    total: el.total,
                  },
                ];
              } else {
                newObj[el.category] = [
                  // ...newObj[el.category],
                  {
                    year: el.year,
                    month: el.month,
                    budget_amount: el.budgetAmount,
                    purchases: el.purchase,
                    total: el.total,
                  },
                ];
              }
            });
            return Object.keys(newObj).map((el) => ({
              type: el,
              color: "black",
              detail: newObj[el],
              total: newObj[el].reduce(
                (prev, cur) => [
                  prev[0] + cur.budget_amount,
                  prev[1] + cur.purchases,
                  prev[2] + cur.total,
                ],
                [0, 0, 0]
              ),
            }));
          }, {}),
          // {
          //   type: "Food",
          //   color: "rgb(255, 99, 132)",
          //   detail: [
          //     {
          //       month: "January",
          //       budget_amount: 375.0,
          //       purchases: 220.0,
          //       total: 155.0,
          //     },
          //     {
          //       month: "February",
          //       budget_amount: 35.0,
          //       purchases: 55.0,
          //       total: 20.0,
          //     },
          //     {
          //       month: "March",
          //       budget_amount: "$25.0",
          //       purchases: "$35.0",
          //       total: -10.0,
          //     },
          //     {
          //       month: "April",
          //       budget_amount: "$25.0",
          //       purchases: "$35.0",
          //       total: -10.0,
          //     },
          //     {
          //       month: <span className="color_base_primary">Total</span>,
          //       budget_amount: <span className="color_base_primary">$735.00</span>,
          //       purchases: <span className="color_base_primary">$335.00</span>,
          //       total: <span className="color_base_primary">$360.00</span>,
          //     },
          //   ],
          // },
          // {
          //   type: "Utility",
          //   color: "orange",
          //   detail: [
          //     {
          //       month: "January",
          //       budget_amount: 0.0,
          //       purchases: 0.0,
          //       total: 20.0,
          //     },
          //     {
          //       month: "February",
          //       budget_amount: 35.0,
          //       purchases: 55.0,
          //       total: -20.0,
          //     },
          //     {
          //       month: "March",
          //       budget_amount: 25.0,
          //       purchases: 35.0,
          //       total: -10.0,
          //     },
          //     {
          //       month: "April",
          //       budget_amount: 25.0,
          //       purchases: 31.0,
          //       total: 10.0,
          //     },
          //     {
          //       month: <span className="color_base_primary">Total</span>,
          //       budget_amount: <span className="color_base_primary">$735.00</span>,
          //       purchases: <span className="color_base_primary">$335.00</span>,
          //       total: <span className="color_base_primary">$360.00</span>,
          //     },
          //   ],
          // },
          // {
          //   type: "Dinner",
          //   color: "blue",
          //   detail: [
          //     {
          //       month: "January",
          //       budget_amount: 0.0,
          //       purchases: 0.0,
          //       total: 50.0,
          //     },
          //     {
          //       month: "February",
          //       budget_amount: 35.0,
          //       purchases: 55.0,
          //       total: 100.0,
          //     },
          //     {
          //       month: "March",
          //       budget_amount: 25.0,
          //       purchases: 35.0,
          //       total: 200.0,
          //     },
          //     {
          //       month: "April",
          //       budget_amount: 25.0,
          //       purchases: 31.0,
          //       total: 90.0,
          //     },
          //     {
          //       month: <span className="color_base_primary">Total</span>,
          //       budget_amount: <span className="color_base_primary">$735.00</span>,
          //       purchases: <span className="color_base_primary">$335.00</span>,
          //       total: <span className="color_base_primary">$360.00</span>,
          //     },
          //   ],
          // },
        ],

        full_emergency_funds: {
          data: [
            {
              summer: "Monthly expenses- Fixed",
              monthly: response?.full_emergency_fund.fixedExpense_Month,
              // three_month: "#REF!",
              // six_month: "#REF!",
              // nine_month: "#REF!",
              // twelve_month: "#REF!",
            },
            {
              summer: "Variable expenses",
              monthly: response?.full_emergency_fund.variableExpense_Month,
              // three_month: "#REF!",
              // six_month: "#REF!",
              // nine_month: "#REF!",
              // twelve_month: "#REF!",
            },
            {
              summer: "Flexible expenses",
              monthly: response?.full_emergency_fund.flexibleExpense_Month,
              // three_month: "$0.00",
              // six_month: "$0.00",
              // nine_month: "$0.00",
              // twelve_month: "$0.00",
            },
            // {
            //   summer: "Emergency fund requirement",
            //   monthly: response?.full_emergency_fund.monthly,
            //   // three_month: "#REF!",
            //   // six_month: "#REF!",
            //   // nine_month: "#REF!",
            //   // twelve_month: "#REF!",
            // },
          ],
          total: response?.full_emergency_fund.monthly,
        },
        monthlyaffirmation: [response.monthly_affirmation].reduce(
          (prev, curr) => {
            let income_arr = curr.income_type;
            let monthlyfixed_arr = curr.monthly_fixed_expenses;
            let monthlyvariable_arr = curr.monthly_variable_expenses;
            let monthlyflexible_arr = curr.monthly_flexible_expenses;
            let unique_table1 = {};
            let unique_table2 = {};
            let unique_table3 = {};
            let unique_table4 = {};

            income_arr?.forEach((el) => {
              unique_table1[el.monthYear] = [
                ["Hourly wage income", el.hourly || 0],
                ["Other income", el.other || 0],
                // [
                //   <span className="color_base_primary">Total income</span>,
                //   <span className="color_base_primary">
                //     {(el.hourly || 0) + (el.total || 0)}
                //   </span>,
                // ],
              ];
            });
            monthlyfixed_arr?.forEach((el) => {
              // console.log(el);
              if (unique_table2[el.monthYear]) {
                unique_table2[el.monthYear] = [
                  ...unique_table2[el.monthYear],
                  [el.expenseName, el.expenseAmount || 0],
                ];
              } else {
                unique_table2[el.monthYear] = [
                  [el.expenseName, el.expenseAmount || 0],
                ];
              }
            });
            monthlyvariable_arr?.forEach((el) => {
              // console.log(el);
              if (unique_table3[el.monthYear]) {
                unique_table3[el.monthYear] = [
                  ...unique_table3[el.monthYear],
                  [el.expenseName, el.expenseAmount || 0],
                ];
              } else {
                unique_table3[el.monthYear] = [
                  [el.expenseName, el.expenseAmount || 0],
                ];
              }
            });
            monthlyflexible_arr?.forEach((el) => {
              // console.log(el);
              if (unique_table4[el.monthYear]) {
                unique_table4[el.monthYear] = [
                  ...unique_table4[el.monthYear],
                  [el.expenseName, el.expenseAmount || 0],
                ];
              } else {
                unique_table4[el.monthYear] = [
                  [el.expenseName, el.expenseAmount || 0],
                ];
              }
            });
            // console.log(unique_table1, unique_table2, unique_table3, unique_table4);
            return {
              income_type: unique_table1,
              monthly_fixed_expenses: unique_table2,
              monthly_variable_expenses: unique_table3,
              monthly_flexible_expenses: unique_table4,
            };
          },
          {}
        ),
        weeklysaving: {
          savinggoal: response?.week_saving_challenge_52?.savinggoal,
          savingtotal: response?.week_saving_challenge_52?.savingtotal,
          data: [
            ...response?.week_saving_challenge_52?.perweekjson?.map((el) => ({
              Deposit: el.weeklydeposit,
              Balance: el.balance,
              week: el.weeknumber,
              actualdeposit: el.actualdeposit,
            })),
          ],
        },
      };
      return callback({ status: true, page_data });
      // console.log(JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const page_data = {
  full_budget: {
    income_type: [
      ["#REF!", "#REF!"],
      ["#REF!", "#REF!"],
      [
        <span className="color_base_primary">Total income</span>,
        <span className="color_base_primary">#REF!</span>,
      ],
    ],
    monthly_fixed_expenses: [
      ["#REF!", "#REF!"],
      ["#REF!", "#REF!"],
      ["#REF!", "#REF!"],
      ["#REF!", "#REF!"],
      ["#REF!", "#REF!"],
      [
        <span className="color_base_primary">Total income</span>,
        <span className="color_base_primary">#REF!</span>,
      ],
    ],
    monthly_variable_expenses: [
      ["#REF!", "#REF!"],
      ["#REF!", "#REF!"],
      ["#REF!", "#REF!"],
      ["#REF!", "#REF!"],
      ["#REF!", "#REF!"],
      [
        <span className="color_base_primary">Total income</span>,
        <span className="color_base_primary">#REF!</span>,
      ],
    ],
    monthly_flexible_expenses: [
      ["Eating out", "$0"],
      ["Gym membership", "$0"],
      ["Hobbies", ""],
      ["Entertainment", ""],
      ["Coble", ""],
      ["Streaming", ""],
      ["OPEN", "$0"],
      ["OPEN", ""],
      ["OPEN", "$0"],
      ["OPEN", ""],
      [
        <span className="color_base_primary">Total flexible expenses</span>,
        <span className="color_base_primary">#0</span>,
      ],
    ],
    full_budget: [["Discretionary income", "#REF!"]],
  },
  debt_elimination_calculator: {
    additional_payment_toward_debt: 0,
    first_debt_payed: "16 months",
    approximate_interst_paid: "6,967.18",
    months_till_payoff: "27 months",
    trans_data: [
      // [
      //   {
      //     card_name: "Discover",
      //     balance: "$16,403.00",
      //     monthly_payment: "$555.00",
      //     interest_rate: "5.00%",
      //   },
      //   {
      //     card_name: "Target",
      //     balance: "$17,060.00",
      //     monthly_payment: "$931.00",
      //     interest_rate: "5.00%",
      //   },
      //   {
      //     card_name: "US bank credit",
      //     balance: "$6,082.00",
      //     monthly_payment: "$780.00",
      //     interest_rate: "9.00%",
      //   },
      //   {
      //     card_name: "Capital one",
      //     balance: "$5,595.00",
      //     monthly_payment: "$981.00",
      //     interest_rate: "12.00%",
      //   },
      //   {
      //     card_name: "Chase",
      //     balance: "$6,763.00",
      //     monthly_payment: "$512.00",
      //     interest_rate: "29.00%",
      //   },
      //   {
      //     card_name: "Wells credit",
      //     balance: "$11,247.00",
      //     monthly_payment: "$581.00",
      //     interest_rate: "15.00%",
      //   },
      // ],
      // [
      //   {
      //     card_name: "Discover",
      //     balance: "$1643.00",
      //     monthly_payment: "$55.00",
      //     interest_rate: "5.00%",
      //   },
      //   {
      //     card_name: "Target",
      //     balance: "$17,060.00",
      //     monthly_payment: "$931.00",
      //     interest_rate: "5.00%",
      //   },
      //   {
      //     card_name: "US bank credit",
      //     balance: "$6,082.00",
      //     monthly_payment: "$780.00",
      //     interest_rate: "9.00%",
      //   },
      //   {
      //     card_name: "Capital one",
      //     balance: "$5,595.00",
      //     monthly_payment: "$981.00",
      //     interest_rate: "12.00%",
      //   },
      //   {
      //     card_name: "Chase",
      //     balance: "$6,763.00",
      //     monthly_payment: "$512.00",
      //     interest_rate: "29.00%",
      //   },
      //   {
      //     card_name: "Wells credit",
      //     balance: "$11,247.00",
      //     monthly_payment: "$581.00",
      //     interest_rate: "15.00%",
      //   },
      // ],
      // [
      //   {
      //     card_name: "Discover",
      //     balance: "$0.00",
      //     monthly_payment: "$55.00",
      //     interest_rate: "5.00%",
      //   },
      //   {
      //     card_name: "Target",
      //     balance: "$17,060.00",
      //     monthly_payment: "$931.00",
      //     interest_rate: "5.00%",
      //   },
      //   {
      //     card_name: "US bank credit",
      //     balance: "$6,082.00",
      //     monthly_payment: "$780.00",
      //     interest_rate: "9.00%",
      //   },
      //   {
      //     card_name: "Capital one",
      //     balance: "$5,595.00",
      //     monthly_payment: "$981.00",
      //     interest_rate: "12.00%",
      //   },
      //   {
      //     card_name: "Chase",
      //     balance: "$6,763.00",
      //     monthly_payment: "$512.00",
      //     interest_rate: "29.00%",
      //   },
      //   {
      //     card_name: "Wells credit",
      //     balance: "$11,247.00",
      //     monthly_payment: "$581.00",
      //     interest_rate: "15.00%",
      //   },
      // ],
    ],
  },
  digital_envelope: [
    // data: [
    {
      type: "Food",
      color: "rgb(255, 99, 132)",
      detail: [
        {
          month: "January",
          budget_amount: 375.0,
          purchases: 220.0,
          total: 155.0,
        },
        {
          month: "February",
          budget_amount: 35.0,
          purchases: 55.0,
          total: 20.0,
        },
        {
          month: "March",
          budget_amount: "$25.0",
          purchases: "$35.0",
          total: -10.0,
        },
        {
          month: "April",
          budget_amount: "$25.0",
          purchases: "$35.0",
          total: -10.0,
        },
        {
          month: <span className="color_base_primary">Total</span>,
          budget_amount: <span className="color_base_primary">$735.00</span>,
          purchases: <span className="color_base_primary">$335.00</span>,
          total: <span className="color_base_primary">$360.00</span>,
        },
      ],
    },
    {
      type: "Utility",
      color: "orange",
      detail: [
        {
          month: "January",
          budget_amount: 0.0,
          purchases: 0.0,
          total: 20.0,
        },
        {
          month: "February",
          budget_amount: 35.0,
          purchases: 55.0,
          total: -20.0,
        },
        {
          month: "March",
          budget_amount: 25.0,
          purchases: 35.0,
          total: -10.0,
        },
        {
          month: "April",
          budget_amount: 25.0,
          purchases: 31.0,
          total: 10.0,
        },
        {
          month: <span className="color_base_primary">Total</span>,
          budget_amount: <span className="color_base_primary">$735.00</span>,
          purchases: <span className="color_base_primary">$335.00</span>,
          total: <span className="color_base_primary">$360.00</span>,
        },
      ],
    },
    {
      type: "Dinner",
      color: "blue",
      detail: [
        {
          month: "January",
          budget_amount: 0.0,
          purchases: 0.0,
          total: 50.0,
        },
        {
          month: "February",
          budget_amount: 35.0,
          purchases: 55.0,
          total: 100.0,
        },
        {
          month: "March",
          budget_amount: 25.0,
          purchases: 35.0,
          total: 200.0,
        },
        {
          month: "April",
          budget_amount: 25.0,
          purchases: 31.0,
          total: 90.0,
        },
        {
          month: <span className="color_base_primary">Total</span>,
          budget_amount: <span className="color_base_primary">$735.00</span>,
          purchases: <span className="color_base_primary">$335.00</span>,
          total: <span className="color_base_primary">$360.00</span>,
        },
      ],
    },
    // ],
  ],
  full_emergency_funds: {
    data: [
      {
        summer: "Monthly expenses- Fixed",
        monthly: "#REF!",
        three_month: "#REF!",
        six_month: "#REF!",
        nine_month: "#REF!",
        twelve_month: "#REF!",
      },
      {
        summer: "Variable expenses",
        monthly: "#REF!",
        three_month: "#REF!",
        six_month: "#REF!",
        nine_month: "#REF!",
        twelve_month: "#REF!",
      },
      {
        summer: "Flexible expenses",
        monthly: "$0.00",
        three_month: "$0.00",
        six_month: "$0.00",
        nine_month: "$0.00",
        twelve_month: "$0.00",
      },
      {
        summer: "Emergency fund requirement",
        monthly: "#REF!",
        three_month: "#REF!",
        six_month: "#REF!",
        nine_month: "#REF!",
        twelve_month: "#REF!",
      },
    ],
  },
  monthlyaffirmation: {
    data: [
      {
        incometype: [
          ["Hourly wage income", "$1.00"],
          ["Other income", "$0.00"],
          [
            <span className="color_base_primary">Total income</span>,
            <span className="color_base_primary">#REF!</span>,
          ],
        ],
        monthly_fixed_expenses: [
          ["Housing", "$0.00"],
          ["Car/ Transit", "$0.00"],
          ["Student loans", "$0.00"],
          ["Various loans", "$0.00"],
          ["Cell phone", "$0.00"],
          ["Insurance premium", "$0.00"],
          ["Childcare costs", "$0.00"],
          ["Tuition and fees", "$0.00"],
          ["Open", "$0.00"],
          ["Open", "$0.00"],
          [
            <span className="color_base_primary">Total income</span>,
            <span className="color_base_primary">#REF!</span>,
          ],
        ],
        monthly_variable_expenses: [
          ["Utilities", "$0.00"],
          ["Prescriptions", "$0.00"],
          ["Credit card bills", "$0.00"],
          ["Groceries", "$0.00"],
          ["Gas/ car maintainance", "$0.00"],
          ["Medicinal bills", "$0.00"],
          ["Open", "$0.00"],
          ["Open", "$0.00"],
          [
            <span className="color_base_primary">Total income</span>,
            <span className="color_base_primary">#REF!</span>,
          ],
        ],
        monthly_flexible_expenses: [
          ["Utilities", "$0.00"],
          ["Prescriptions", "$0.00"],
          ["Credit card bills", "$0.00"],
          ["Groceries", "$0.00"],
          ["Gas/ car maintainance", "$0.00"],
          ["Medicinal bills", "$0.00"],
          ["Open", "$0.00"],
          ["Open", "$0.00"],
          [
            <span className="color_base_primary">Total income</span>,
            <span className="color_base_primary">#REF!</span>,
          ],
        ],
        full_budget: [["Discretionary income", "$0.00"]],
      },
      {
        incometype: [
          ["Hourly wage income", "$2.00"],
          ["Other income", "$0.00"],
          [
            <span className="color_base_primary">Total income</span>,
            <span className="color_base_primary">#REF!</span>,
          ],
        ],
        monthly_fixed_expenses: [
          ["Housing", "$0.00"],
          ["Car/ Transit", "$0.00"],
          ["Student loans", "$0.00"],
          ["Various loans", "$0.00"],
          ["Cell phone", "$0.00"],
          ["Insurance premium", "$0.00"],
          ["Childcare costs", "$0.00"],
          ["Tuition and fees", "$0.00"],
          ["Open", "$0.00"],
          ["Open", "$0.00"],
          [
            <span className="color_base_primary">Total income</span>,
            <span className="color_base_primary">#REF!</span>,
          ],
        ],
        monthly_variable_expenses: [
          ["Utilities", "$0.00"],
          ["Prescriptions", "$0.00"],
          ["Credit card bills", "$0.00"],
          ["Groceries", "$0.00"],
          ["Gas/ car maintainance", "$0.00"],
          ["Medicinal bills", "$0.00"],
          ["Open", "$0.00"],
          ["Open", "$0.00"],
          [
            <span className="color_base_primary">Total income</span>,
            <span className="color_base_primary">#REF!</span>,
          ],
        ],
        monthly_flexible_expenses: [
          ["Utilities", "$0.00"],
          ["Prescriptions", "$0.00"],
          ["Credit card bills", "$0.00"],
          ["Groceries", "$0.00"],
          ["Gas/ car maintainance", "$0.00"],
          ["Medicinal bills", "$0.00"],
          ["Open", "$0.00"],
          ["Open", "$0.00"],
          [
            <span className="color_base_primary">Total income</span>,
            <span className="color_base_primary">#REF!</span>,
          ],
        ],
        full_budget: [["Discretionary income", "$0.00"]],
      },
    ],
  },
  weeklysaving: {
    data: [
      {
        Deposit: 0.87,
        Balance: 0.87,
        week: 1,
      },
      {
        Deposit: 0.87,
        Balance: 1.87,
        week: 2,
      },
      {
        Deposit: 0.87,
        Balance: 0.87,
        week: 3,
      },
      {
        Deposit: 0.87,
        Balance: 19.87,
        week: 4,
      },
      {
        Deposit: 0.87,
        Balance: 0.87,
        week: 5,
      },
      {
        Deposit: 0.87,
        Balance: 0.87,
        week: 6,
      },
      {
        Deposit: 0.87,
        Balance: 0.87,
        week: 7,
      },
      {
        Deposit: 0.87,
        Balance: 0.87,
        week: 8,
      },
    ],
  },
  colordata: [
    "#F00",
    "#0F0",
    "#00F",
    "#FFFF00",
    "#FF00FF",
    "#00FFFF",
    "#FFA500",
    "#800080",
    "#008000",
    "#800000",
    "#008080",
    "#FFC0CB",
    "#A6A67B",
    "#F0E68C",
    "#00CED1",
    "#FF69B4",
    "#B35656",
    "#6A5ACD",
    "#8B008B",
    "#FF4500",
  ],
};

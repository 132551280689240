import React, { useState } from "react";
import { Images } from "../../image/Images";

const Switch = ({ custclass, onevent, page_values }) => {
  const [controller, setController] = useState(true);
  function btnSwitch() {
    setController(!controller);
    onevent(controller);
  }
  return (
    <>
      <div className={"switch_main " + (custclass ? ` ${custclass}` : "")}>
        <div
          className={"switch_title " + (controller ? "active" : "")}
          onClick={() => {
            setController(true);
          }}
        >
          {page_values.right}
        </div>
        <div
          className={`switch-btn  ${
            controller ? " leftBorder" : " rightBorder"
          }`}
          onClick={() => btnSwitch()}
        >
          <div
            className={
              controller ? `switch-btn-circle left ` : `switch-btn-circle right`
            }
          >
            <Images get={"doller"} />
          </div>
        </div>
        <div
          className={"switch_title " + (controller ? "" : "active")}
          onClick={() => {
            setController(false);
          }}
        >
          {page_values.left}
        </div>
      </div>
    </>
  );
};

export default Switch;

import React from "react";
import { Images } from "../../image/Images";
import { Container } from "../Container";
import { nav_data } from "../../utils/utils";

export const Main_Section2 = ({ setroute }) => {
  return (
    <div className="section2">
      <Container>
        <div className="txt" >We are here to help you with</div>
        {/* <div className="txt">
            <svg xmlns="http://www.w3.org/2000/svg" width="435" height="32" viewBox="0 0 435 32" fill="none">
            <path d="M0.352051 2.35004H4.80005L8.67205 18.958H8.86405L13.9841 2.35004H17.8561L22.9761 18.958H23.1681L27.0721 2.35004H31.5201L25.7601 24.782H20.4161L16.0321 10.286H15.8401L11.4561 24.782H6.11205L0.352051 2.35004Z" fill="black"/>
            <path d="M40.0323 25.006C38.2616 25.006 36.7683 24.6007 35.5523 23.79C34.3363 22.958 33.4296 21.8807 32.8323 20.558C32.2563 19.214 31.9683 17.774 31.9683 16.238C31.9683 14.702 32.2563 13.2727 32.8323 11.95C33.4296 10.606 34.3363 9.51804 35.5523 8.68604C36.7683 7.85404 38.2723 7.43804 40.0643 7.43804C42.4323 7.43804 44.3096 8.19537 45.6963 9.71004C47.1043 11.2247 47.8083 13.4114 47.8083 16.27V17.486H36.2243V17.742C36.2243 18.766 36.5763 19.6514 37.2803 20.398C37.9843 21.1234 38.8696 21.486 39.9363 21.486C40.7683 21.486 41.4723 21.294 42.0483 20.91C42.6243 20.526 43.0403 20.0034 43.2963 19.342H47.6483C47.2003 21.006 46.3256 22.3714 45.0243 23.438C43.723 24.4834 42.059 25.006 40.0323 25.006ZM43.5523 14.606C43.5096 13.262 43.1683 12.27 42.5283 11.63C41.8883 10.99 41.0456 10.67 40.0003 10.67C38.891 10.67 38.0163 11.022 37.3763 11.726C36.7363 12.43 36.395 13.39 36.3523 14.606H43.5523Z" fill="black"/>
            <path d="M64.2768 25.006C62.1861 25.006 60.5541 24.206 59.3808 22.606C58.2075 21.006 57.6208 18.894 57.6208 16.27C57.6208 13.6034 58.2075 11.47 59.3808 9.87004C60.5755 8.24871 62.2395 7.43804 64.3728 7.43804C65.8021 7.43804 66.9221 7.76871 67.7328 8.43004C68.5435 9.07004 69.1195 9.75271 69.4608 10.478H69.7168V7.85404H74.1648V24.75H69.7168V21.838H69.4608C69.0768 22.6274 68.4795 23.3527 67.6688 24.014C66.8581 24.6754 65.7275 25.006 64.2768 25.006ZM65.9088 21.422C67.1675 21.422 68.1168 20.91 68.7568 19.886C69.3968 18.862 69.7168 17.6354 69.7168 16.206C69.7168 14.7767 69.3968 13.5607 68.7568 12.558C68.1381 11.5554 67.1888 11.054 65.9088 11.054C64.6288 11.054 63.6795 11.5554 63.0608 12.558C62.4421 13.5607 62.1328 14.7767 62.1328 16.206C62.1328 17.6567 62.4421 18.894 63.0608 19.918C63.6795 20.9207 64.6288 21.422 65.9088 21.422Z" fill="black"/>
            <path d="M78.1708 7.85404H82.5548V10.542H82.7788C83.0561 9.79537 83.5681 9.16604 84.3148 8.65404C85.0615 8.12071 86.0215 7.85404 87.1948 7.85404C87.6428 7.85404 88.0268 7.89671 88.3468 7.98204L88.1548 12.046C87.6215 11.8967 87.0561 11.822 86.4588 11.822C85.1361 11.822 84.1548 12.174 83.5148 12.878C82.8748 13.5607 82.5548 14.702 82.5548 16.302V24.75H78.1708V7.85404Z" fill="black"/>
            <path d="M97.8761 25.006C96.1054 25.006 94.612 24.6007 93.396 23.79C92.18 22.958 91.2734 21.8807 90.676 20.558C90.1 19.214 89.812 17.774 89.812 16.238C89.812 14.702 90.1 13.2727 90.676 11.95C91.2734 10.606 92.18 9.51804 93.396 8.68604C94.612 7.85404 96.1161 7.43804 97.9081 7.43804C100.276 7.43804 102.153 8.19537 103.54 9.71004C104.948 11.2247 105.652 13.4114 105.652 16.27V17.486H94.0681V17.742C94.0681 18.766 94.4201 19.6514 95.1241 20.398C95.8281 21.1234 96.7134 21.486 97.7801 21.486C98.6121 21.486 99.3161 21.294 99.8921 20.91C100.468 20.526 100.884 20.0034 101.14 19.342H105.492C105.044 21.006 104.169 22.3714 102.868 23.438C101.567 24.4834 99.9027 25.006 97.8761 25.006ZM101.396 14.606C101.353 13.262 101.012 12.27 100.372 11.63C99.7321 10.99 98.8894 10.67 97.8441 10.67C96.7347 10.67 95.8601 11.022 95.2201 11.726C94.5801 12.43 94.2387 13.39 94.1961 14.606H101.396Z" fill="black"/>
            <path d="M116.137 0.974039H120.617V10.446H120.841C121.225 9.61404 121.843 8.91004 122.697 8.33404C123.55 7.73671 124.585 7.43804 125.801 7.43804C127.998 7.43804 129.545 8.14204 130.441 9.55004C131.358 10.958 131.817 12.558 131.817 14.35V24.75H127.401V15.086C127.401 13.8487 127.102 12.878 126.505 12.174C125.929 11.47 125.097 11.118 124.009 11.118C122.942 11.118 122.11 11.4807 121.513 12.206C120.915 12.9314 120.617 13.8914 120.617 15.086V24.75H116.137V0.974039Z" fill="black"/>
            <path d="M142.97 25.006C141.199 25.006 139.706 24.6007 138.49 23.79C137.274 22.958 136.367 21.8807 135.77 20.558C135.194 19.214 134.906 17.774 134.906 16.238C134.906 14.702 135.194 13.2727 135.77 11.95C136.367 10.606 137.274 9.51804 138.49 8.68604C139.706 7.85404 141.21 7.43804 143.002 7.43804C145.37 7.43804 147.247 8.19537 148.634 9.71004C150.042 11.2247 150.746 13.4114 150.746 16.27V17.486H139.162V17.742C139.162 18.766 139.514 19.6514 140.218 20.398C140.922 21.1234 141.807 21.486 142.874 21.486C143.706 21.486 144.41 21.294 144.986 20.91C145.562 20.526 145.978 20.0034 146.234 19.342H150.586C150.138 21.006 149.263 22.3714 147.962 23.438C146.66 24.4834 144.996 25.006 142.97 25.006ZM146.49 14.606C146.447 13.262 146.106 12.27 145.466 11.63C144.826 10.99 143.983 10.67 142.938 10.67C141.828 10.67 140.954 11.022 140.314 11.726C139.674 12.43 139.332 13.39 139.29 14.606H146.49Z" fill="black"/>
            <path d="M153.983 7.85404H158.367V10.542H158.591C158.869 9.79537 159.381 9.16604 160.127 8.65404C160.874 8.12071 161.834 7.85404 163.007 7.85404C163.455 7.85404 163.839 7.89671 164.159 7.98204L163.967 12.046C163.434 11.8967 162.869 11.822 162.271 11.822C160.949 11.822 159.967 12.174 159.327 12.878C158.687 13.5607 158.367 14.702 158.367 16.302V24.75H153.983V7.85404Z" fill="black"/>
            <path d="M173.689 25.006C171.918 25.006 170.425 24.6007 169.209 23.79C167.993 22.958 167.086 21.8807 166.489 20.558C165.913 19.214 165.625 17.774 165.625 16.238C165.625 14.702 165.913 13.2727 166.489 11.95C167.086 10.606 167.993 9.51804 169.209 8.68604C170.425 7.85404 171.929 7.43804 173.721 7.43804C176.089 7.43804 177.966 8.19537 179.353 9.71004C180.761 11.2247 181.465 13.4114 181.465 16.27V17.486H169.881V17.742C169.881 18.766 170.233 19.6514 170.937 20.398C171.641 21.1234 172.526 21.486 173.593 21.486C174.425 21.486 175.129 21.294 175.705 20.91C176.281 20.526 176.697 20.0034 176.953 19.342H181.305C180.857 21.006 179.982 22.3714 178.681 23.438C177.379 24.4834 175.715 25.006 173.689 25.006ZM177.209 14.606C177.166 13.262 176.825 12.27 176.185 11.63C175.545 10.99 174.702 10.67 173.657 10.67C172.547 10.67 171.673 11.022 171.033 11.726C170.393 12.43 170.051 13.39 170.009 14.606H177.209Z" fill="black"/>
            <path d="M198.285 11.31V18.83C198.285 19.5767 198.317 20.1314 198.381 20.494C198.466 20.8567 198.68 21.1447 199.021 21.358C199.362 21.5714 199.917 21.678 200.685 21.678C201.133 21.678 201.592 21.6354 202.061 21.55V24.622C201.826 24.6647 201.4 24.718 200.781 24.782C200.184 24.846 199.597 24.878 199.021 24.878C197.464 24.878 196.301 24.654 195.533 24.206C194.786 23.7367 194.317 23.1607 194.125 22.478C193.933 21.774 193.837 20.878 193.837 19.79V11.31H191.021V7.85404H193.869V2.35004H198.285V7.85404H202.061V11.31H198.285Z" fill="black"/>
            <path d="M212.97 25.006C211.199 25.006 209.706 24.6114 208.49 23.822C207.295 23.0114 206.399 21.9447 205.802 20.622C205.204 19.2994 204.906 17.8487 204.906 16.27C204.906 14.6914 205.194 13.2407 205.77 11.918C206.367 10.574 207.274 9.49671 208.49 8.68604C209.706 7.87537 211.199 7.47004 212.97 7.47004C214.74 7.47004 216.234 7.87537 217.45 8.68604C218.666 9.47537 219.572 10.5314 220.17 11.854C220.767 13.1767 221.066 14.6274 221.066 16.206C221.066 17.7847 220.767 19.246 220.17 20.59C219.572 21.9127 218.666 22.9794 217.45 23.79C216.234 24.6007 214.74 25.006 212.97 25.006ZM212.97 21.422C214.207 21.422 215.135 20.9314 215.754 19.95C216.394 18.9474 216.714 17.6994 216.714 16.206C216.714 14.7127 216.394 13.486 215.754 12.526C215.135 11.5447 214.207 11.054 212.97 11.054C211.732 11.054 210.804 11.5447 210.186 12.526C209.567 13.486 209.258 14.7127 209.258 16.206C209.258 17.6994 209.567 18.9474 210.186 19.95C210.826 20.9314 211.754 21.422 212.97 21.422Z" fill="black"/>
            <path d="M231.574 0.974039H236.054V10.446H236.278C236.662 9.61404 237.281 8.91004 238.134 8.33404C238.987 7.73671 240.022 7.43804 241.238 7.43804C243.435 7.43804 244.982 8.14204 245.878 9.55004C246.795 10.958 247.254 12.558 247.254 14.35V24.75H242.838V15.086C242.838 13.8487 242.539 12.878 241.942 12.174C241.366 11.47 240.534 11.118 239.446 11.118C238.379 11.118 237.547 11.4807 236.95 12.206C236.353 12.9314 236.054 13.8914 236.054 15.086V24.75H231.574V0.974039Z" fill="black"/>
            <path d="M258.407 25.006C256.637 25.006 255.143 24.6007 253.927 23.79C252.711 22.958 251.805 21.8807 251.207 20.558C250.631 19.214 250.343 17.774 250.343 16.238C250.343 14.702 250.631 13.2727 251.207 11.95C251.805 10.606 252.711 9.51804 253.927 8.68604C255.143 7.85404 256.647 7.43804 258.439 7.43804C260.807 7.43804 262.685 8.19537 264.071 9.71004C265.479 11.2247 266.183 13.4114 266.183 16.27V17.486H254.599V17.742C254.599 18.766 254.951 19.6514 255.655 20.398C256.359 21.1234 257.245 21.486 258.311 21.486C259.143 21.486 259.847 21.294 260.423 20.91C260.999 20.526 261.415 20.0034 261.671 19.342H266.023C265.575 21.006 264.701 22.3714 263.399 23.438C262.098 24.4834 260.434 25.006 258.407 25.006ZM261.927 14.606C261.885 13.262 261.543 12.27 260.903 11.63C260.263 10.99 259.421 10.67 258.375 10.67C257.266 10.67 256.391 11.022 255.751 11.726C255.111 12.43 254.77 13.39 254.727 14.606H261.927Z" fill="black"/>
            <path d="M274.701 24.878C273.122 24.878 271.949 24.6434 271.181 24.174C270.434 23.6834 269.965 23.086 269.773 22.382C269.602 21.6567 269.517 20.7394 269.517 19.63V0.750038H273.933V18.734C273.933 19.502 273.965 20.078 274.029 20.462C274.114 20.8247 274.327 21.1234 274.669 21.358C275.031 21.5714 275.597 21.678 276.365 21.678C276.621 21.678 276.919 21.6567 277.261 21.614V24.686C276.173 24.814 275.319 24.878 274.701 24.878Z" fill="black"/>
            <path d="M290.055 25.006C288.626 25.006 287.506 24.6754 286.695 24.014C285.884 23.3527 285.287 22.6274 284.903 21.838H284.647V31.406H280.199V7.85404H284.647V10.478H284.903C285.244 9.75271 285.82 9.07004 286.631 8.43004C287.442 7.76871 288.572 7.43804 290.023 7.43804C292.178 7.43804 293.831 8.23804 294.983 9.83804C296.156 11.438 296.743 13.582 296.743 16.27C296.743 18.894 296.156 21.006 294.983 22.606C293.831 24.206 292.188 25.006 290.055 25.006ZM288.455 21.486C289.735 21.486 290.684 20.974 291.303 19.95C291.943 18.926 292.263 17.6887 292.263 16.238C292.263 14.83 291.943 13.6247 291.303 12.622C290.684 11.598 289.735 11.086 288.455 11.086C287.196 11.086 286.247 11.598 285.607 12.622C284.967 13.646 284.647 14.862 284.647 16.27C284.647 17.6994 284.967 18.926 285.607 19.95C286.247 20.974 287.196 21.486 288.455 21.486Z" fill="black"/>
            <path d="M315.45 27.15C315.151 27.9394 314.831 28.6327 314.49 29.23C314.148 29.8487 313.54 30.446 312.666 31.022C311.812 31.598 310.682 31.886 309.274 31.886C308.676 31.886 308.164 31.8327 307.738 31.726C307.311 31.6407 307.044 31.5874 306.938 31.566V28.462C307.279 28.5474 307.738 28.59 308.314 28.59C309.018 28.59 309.583 28.4727 310.01 28.238C310.436 28.0034 310.778 27.694 311.034 27.31C311.29 26.926 311.546 26.3927 311.802 25.71L312.346 24.366L306.266 7.85404H310.746L314.394 19.47H314.586L318.426 7.85404H322.906L315.45 27.15Z" fill="black"/>
            <path d="M332.407 25.006C330.637 25.006 329.143 24.6114 327.927 23.822C326.733 23.0114 325.837 21.9447 325.239 20.622C324.642 19.2994 324.343 17.8487 324.343 16.27C324.343 14.6914 324.631 13.2407 325.207 11.918C325.805 10.574 326.711 9.49671 327.927 8.68604C329.143 7.87537 330.637 7.47004 332.407 7.47004C334.178 7.47004 335.671 7.87537 336.887 8.68604C338.103 9.47537 339.01 10.5314 339.607 11.854C340.205 13.1767 340.503 14.6274 340.503 16.206C340.503 17.7847 340.205 19.246 339.607 20.59C339.01 21.9127 338.103 22.9794 336.887 23.79C335.671 24.6007 334.178 25.006 332.407 25.006ZM332.407 21.422C333.645 21.422 334.573 20.9314 335.191 19.95C335.831 18.9474 336.151 17.6994 336.151 16.206C336.151 14.7127 335.831 13.486 335.191 12.526C334.573 11.5447 333.645 11.054 332.407 11.054C331.17 11.054 330.242 11.5447 329.623 12.526C329.005 13.486 328.695 14.7127 328.695 16.206C328.695 17.6994 329.005 18.9474 329.623 19.95C330.263 20.9314 331.191 21.422 332.407 21.422Z" fill="black"/>
            <path d="M358.997 24.75H354.549V22.062H354.325C353.941 22.894 353.333 23.6087 352.501 24.206C351.69 24.782 350.687 25.07 349.493 25.07C347.338 25.07 345.823 24.366 344.949 22.958C344.074 21.5287 343.637 19.9287 343.637 18.158V7.85404H348.053V17.422C348.053 18.638 348.33 19.6087 348.885 20.334C349.439 21.038 350.229 21.39 351.253 21.39C352.362 21.39 353.183 21.0167 353.717 20.27C354.271 19.5234 354.549 18.574 354.549 17.422V7.85404H358.997V24.75Z" fill="black"/>
            <path d="M368.734 7.85404H372.862L375.742 19.95H375.902L379.326 7.85404H383.198L386.718 19.95H386.878L389.662 7.85404H393.79L389.47 24.75H384.126L381.406 14.574H381.182L378.494 24.75H373.15L368.734 7.85404Z" fill="black"/>
            <path d="M396.774 7.85404H401.19V24.75H396.774V7.85404ZM398.95 4.94204C398.246 4.94204 397.659 4.70737 397.19 4.23804C396.72 3.74737 396.486 3.16071 396.486 2.47804C396.486 1.77404 396.72 1.18737 397.19 0.718039C397.659 0.248705 398.246 0.0140381 398.95 0.0140381C399.654 0.0140381 400.24 0.259371 400.71 0.750038C401.179 1.21937 401.414 1.79537 401.414 2.47804C401.414 3.16071 401.179 3.74737 400.71 4.23804C400.24 4.70737 399.654 4.94204 398.95 4.94204Z" fill="black"/>
            <path d="M411.691 11.31V18.83C411.691 19.5767 411.723 20.1314 411.787 20.494C411.873 20.8567 412.086 21.1447 412.427 21.358C412.769 21.5714 413.323 21.678 414.091 21.678C414.539 21.678 414.998 21.6354 415.467 21.55V24.622C415.233 24.6647 414.806 24.718 414.187 24.782C413.59 24.846 413.003 24.878 412.427 24.878C410.87 24.878 409.707 24.654 408.939 24.206C408.193 23.7367 407.723 23.1607 407.531 22.478C407.339 21.774 407.243 20.878 407.243 19.79V11.31H404.427V7.85404H407.275V2.35004H411.691V7.85404H415.467V11.31H411.691Z" fill="black"/>
            <path d="M418.918 0.974039H423.398V10.446H423.622C424.006 9.61404 424.624 8.91004 425.478 8.33404C426.331 7.73671 427.366 7.43804 428.582 7.43804C430.779 7.43804 432.326 8.14204 433.222 9.55004C434.139 10.958 434.598 12.558 434.598 14.35V24.75H430.182V15.086C430.182 13.8487 429.883 12.878 429.286 12.174C428.71 11.47 427.878 11.118 426.79 11.118C425.723 11.118 424.891 11.4807 424.294 12.206C423.696 12.9314 423.398 13.8914 423.398 15.086V24.75H418.918V0.974039Z" fill="black"/>
          </svg>
        </div> */}
        <div className="navigation_group">
          {nav_data.map((el) => (
            <div className="nav_item" onClick={() => setroute(el.to)}>
              <div className="nav_item_icon">
                <Images get={el.icon} />
              </div>
              <div className="nav_txt">{el.name}</div>
              <div className="nav_goto">
                <Images get={"line_arrow_right"} />
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

import React from "react";

export const Table = ({ tclass, theader, tbody }) => {
  return (
    <div className={`table ${tclass}`}>
      <div className="table_main">
        <div className="table_header">
          {theader.map((el, i) => (
            <div className={`col${i + 1}`}>{el}</div>
          ))}
        </div>
        <div className="table_body">
          <div className="header_bg"></div>
          {tbody?.map((el, i) => (
            <div className="row">
              {
                // el?
                el.map((el1, i1) => (
                  <div className={`col${i1 + 1}`}>{el1}</div>
                ))
                // : "No Data"
              }
            </div>
          ))}
          {tbody?.length == 0 || !tbody ? (
            <div className="no_data">No Data</div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
